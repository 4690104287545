import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useMessage } from "src/view/components/MessageContext.tsx";
import { useGlobalState } from "src/hook/useGlobalState.ts";
import { Btc } from "src/api";
import { signMessage, getPublicKey } from "@joyid/bitcoin";
import { isMobileOrTablet } from "src/utils/utils.ts";

interface BindVisibleProps {
    closeVisible: () => void
    bitCell: BitCell
    bindSuccess: () => void
}
interface BitCell {
    bitcellId: string
    boundEvmAddr: string | null
}
export const BindVisible:React.FC<BindVisibleProps> = ({ closeVisible, bitCell,bindSuccess }) => {
    const [value, setValue] = useState('')
    const loading = useRef(false)
    const { btcAddress } = useGlobalState()
    const { showMessage, closeMessage } = useMessage()
    const isEVMAddress = (address: string): boolean => {
        // 正则表达式：以0x开头，只包含字母和数字（不包括中文、空格和符号）
        const evmPattern = /^0x[a-zA-Z0-9]{1,}$/;
        // 测试地址是否符合正则表达式
        return evmPattern.test(address);
    };
    const changeValue = (value: any) => {
        setValue(value.target.value)
    }

    const bindApi = (signatureMsg: string, btcPubkey: string) => {
        const formData= {
            bitcellId: bitCell.bitcellId,
            btcAddress: btcAddress,
            btcPubkey: btcPubkey,
            ethAddress: value,
            signatureMsg: signatureMsg
        }
        Btc.bindEVMAddress(formData).then((res: any) => {
            if (res.code === 200) {
                showMessage('Successfully Bound')
                loading.current = false
                bindSuccess()
                setValue('')
            } else {
                loading.current = false
                showMessage(res.message)
            }
        })
    }
    const okxSignMessage = async () => {
        if (isMobileOrTablet()) {
            try {
                const publicKey = await (window as any).bitcoin.getPublicKey();
                if (publicKey) {
                    Btc.getMessage(publicKey).then(async (res: any) => {
                        if (res.code === 200) {
                            try {
                                const result = await (window as any).bitcoin.signMessage(res.data);
                                bindApi(result, publicKey)
                            } catch (e) {
                                console.log(e);
                                loading.current = false
                            }
                        }
                    })
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            try {
                const publicKey = await (window as any).okxwallet.bitcoin.getPublicKey();
                if (publicKey) {
                    Btc.getMessage(publicKey).then(async (res: any) => {
                        if (res.code === 200) {
                            try {
                                const result = await (window as any).okxwallet.bitcoin.signMessage(res.data);
                                bindApi(result, publicKey)
                            } catch (e) {
                                console.log(e);
                                loading.current = false
                            }
                        }
                    })
                }
            } catch (e) {
                console.log(e)
            }
        }
    }
    const joyIdSignMessage = () => {
        try {
            const publicKey = getPublicKey();
            if (publicKey) {
                Btc.getMessage(publicKey).then(async (res: any) => {
                    if (res.code === 200) {
                        try {
                            const result = await signMessage(res.data);
                            bindApi(result, publicKey)
                        } catch (e) {
                            console.log(e);
                            loading.current = false
                        }
                    }
                })
            }
        } catch (e) {
            console.log(e);
        }
    }
    const [already, setAlready] = useState(false)
    const bindingEVMAddress = () => {
        if (isEVMAddress(value) && !loading.current) {
            loading.current = true
            const btcWallet = localStorage.getItem('btcWallet')
            if (btcWallet === 'joyId') {
                joyIdSignMessage()
            } else {
                okxSignMessage()
            }
        }
    }
    const closeView = () => {
        setValue('')
        setAlready(false)
        closeVisible()
    }
    return (
        <div className={'check-visible'}>
            <div onClick={closeView} className={'close-icon'}></div>
            <div className={'title-bold'}>
                Bind BitCell
            </div>
            <div className={'input-container'}>
                <div className={'title'}>
                    Please bind your (0x..... address)
                </div>
                <div className={'input'}>
                    <Input value={value} onChange={(e) => changeValue(e)}  placeholder={''}></Input>
                </div>
                {
                    value !== '' ?
                        <div className={'error'}>
                            {(!isEVMAddress(value) ?
                                    'Invalid Network Address' :
                                    (already ? `The current BitCell is already bound to an EVM address.` : '')
                            )}
                        </div>
                        : null
                }
                <div className={'button-container'}>
                    <div onClick={() => bindingEVMAddress()} className={`check-btn ${value && isEVMAddress(value) ? '' : 'btn-disabled'}`}>Confirm</div>
                </div>
            </div>
        </div>
    )
}