import { useReadContract, useReadContracts } from 'wagmi';
import { contractAbiMap, ContractAbiTypeEnum } from "src/enums/contractAbiEnum.ts";
import { networkList } from "src/hook/network.ts";
import { networkStatus } from "src/hook/networkStatus.ts";
import { ethers } from "ethers";
const abi = JSON.parse(contractAbiMap.get(ContractAbiTypeEnum.STAKING) as string)
const contractAddress: any = networkList[networkStatus].stakingContract

export const useMinStakingAmount = () => {
    const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'minStakingAmount',
        args: [],
    });

    return { data, isError, isLoading, isSuccess, refetch };
};
export const useStakingDurations = (time: number) => {
    const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'durations',
        args: [time.toString()],
    });
    return { data, isSuccess, refetch }
}

export const useStakingTotalShare = () => {
    const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'totalShare',
        args: [],
    });
    return { refetch }
}
export const useStakingRewardPerBlock = () => {
    const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'rewardPerBlock',
        args: [],
    });
    return { refetch }
}
export const useStakingUserAmount = (address: string) => {
    const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'userAmount',
        args: [address],
    });
    return { refetch }
}

export const useStakingDepositRecord = (address: string, startId: number, pageSize: number) => {
    const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'getUserDepositRecord',
        args: [address, startId, pageSize],
    });
    return { refetch }
}

export const useStakingPendings = (positionIds: number[]) => {
    const iface = new ethers.Interface(abi)
    const encode = positionIds.map((item) => {
        return iface.encodeFunctionData('pending', [item])
    })
    const { refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'multicall',
        args: [encode]
    })
    return {refetch}
}

export const useStakingRewardRecord = (address: string, startId: number, pageSize: number) => {
    const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'getUserRewardRecord',
        args: [address, startId, pageSize],
    });
    return { refetch }
}
export const useStakingWithdrawRecord = (address: string, startId: number, pageSize: number) => {
    const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'getUserWithdrawRecord',
        args: [address, startId, pageSize],
    });
    return { refetch }
}