import "./History.scss"
import { useGlobalState } from "src/hook/useGlobalState.ts";
import { useEffect, useRef, useState } from "react";
import { Btc } from "src/api";
import { Empty } from "antd";
import { calculateRemainingTime, formatDate, getStr, handlePermillage, truncateNumber } from "src/utils/utils.ts";
import { useMessage } from "src/view/components/MessageContext.tsx";
interface ClaimItem {
    bitcellId: string,
    claimList: [
        {
            claimAmount: string,
            recordId: string,
            stage: number,
            status: string
            unlockTime: string
        }
    ],
    ethAddress: string
}
export const BitCellHistory = () => {
    const { address, setIsLoading } = useGlobalState()
    const [claimList, setClaimList] = useState([] as ClaimItem[])
    const { showMessage } = useMessage()
    const getClaimList = () => {
        stopPolling()
        if (location.pathname.includes('/bitcell-claim') && addressRef.current) {
            Btc.getClaimList(address).then((res: any) => {
                startPolling()
                if (res.code === 200) {
                    setClaimList(res.data)
                }
                setIsLoading(false)
            })
        }
    }
    const claimToken = (recordId: string) => {
        const formData = {
            ethAddress: address,
            recordId: [recordId]
        }
        Btc.claimToken(formData).then((res: any) => {
            if (res.code === 200) {
                showMessage('Successfully Claim')
                getClaimList()
            } else {
                showMessage(res.message)
            }
        })
    }
    const claimAll = () => {
        if (claimList.length) {
            const recordIds: string[] = []
            claimList.forEach((item) => {
                item.claimList.forEach((claim) => {
                    if (claim.status === 'available') {
                        recordIds.push(claim.recordId)
                    }
                })
            })
            const formData = {
                ethAddress: address,
                recordId: recordIds
            }
            Btc.claimToken(formData).then((res: any) => {
                if (res.code === 200) {
                    showMessage('Successfully Claim')
                    getClaimList()
                } else {
                    showMessage(res.message)
                }
            })
        }
    }
    const stopPolling = () => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };
    useEffect(() => {
        addressRef.current = address;
        if (address) {
            setIsLoading(true)
            getClaimList()
        }
    }, [address]);
    const addressRef = useRef(address);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const startPolling = () => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            getClaimList();
        }, 5000);
    };
    const [isAll, setIsAll] = useState(false)

    useEffect(() => {
        if (claimList.length) {
            let availableCount = 0
            claimList.forEach((item) => {
                item.claimList.forEach((claim) => {
                    if (claim.status === 'available') {
                        availableCount++
                    }
                })
            })
            if (availableCount) {
                setIsAll(true)
            } else {
                setIsAll(false)
            }
        }
    }, [claimList]);
    return (
        <div className={'bitcell-history'}>
            <div className={'view-bitcell'}>
                <div className={'header-block'}>
                    <div className={'left'}>
                        My BitCell
                    </div>
                    {
                        claimList.length ?
                            <div onClick={claimAll} className={`right ${!isAll ? 'btn-disabled' : ''}`}>
                                Claim All
                            </div>
                            : ''
                    }
                </div>
                <div className={'data-container'}>
                    {
                        claimList.length ?
                            claimList.map((item, index) => (
                                <div key={index} className={'bitcell-item'}>
                                    <div className={'id'}>
                                        ID: {getStr(item.bitcellId,4,4)}
                                    </div>
                                    <div className={'phase-container'}>
                                        {
                                            item.claimList.map((claim, index) => (
                                                <div key={index} className={'phase-item unlock-item'}>
                                                    <div className={'label-container'}>
                                                        <div className={'name'}>Phase {claim.stage}</div>
                                                        <div className={'date'}>{formatDate(parseInt(claim.unlockTime))}</div>
                                                    </div>
                                                    <div className={'value'}>
                                                        {handlePermillage(truncateNumber(claim.claimAmount))}<br/>
                                                        CELA
                                                    </div>
                                                    <div className={'button-container'}>
                                                        <div onClick={() => claimToken(claim.recordId)} className={`btn ${claim.status !== 'available' ? 'btn-disabled' : ''}`}>
                                                            {claim.status === 'claimed' ? 'Claimed' : 'Claim'}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                            : <Empty></Empty>
                    }
                </div>
            </div>
        </div>
    )
}