import './index.scss'
import React, {useEffect, useRef, useState} from "react";
import {Stake} from "./stake";
import {Withdraw} from "./withdraw";
import {Records} from "./records";
import {useGlobalState} from "src/hook/useGlobalState.ts";

import lock from 'img/pledge/lock-icon.png'
import titleIcon from 'img/pledge/title-icon.png'
import rightArrow from 'img/pledge/right-arrow.png'
import { formatEther, parseEther } from "viem";
import {formatTimestampToDate, handlePermillage, truncateNumber} from "src/utils/utils.ts";
import {
    useMinStakingAmount, useStakingDepositRecord,
    useStakingDurations, useStakingPendings,
    useStakingRewardPerBlock, useStakingRewardRecord,
    useStakingTotalShare, useStakingUserAmount
} from "src/hook/useStake.ts";
import {ethers} from "ethers";
import {contractAbiMap, ContractAbiTypeEnum} from "src/enums/contractAbiEnum.ts";
const stakingAbi = JSON.parse(contractAbiMap.get(ContractAbiTypeEnum.STAKING) as string)
const contractAddress: any = networkList[networkStatus].stakingContract
import { useMessage } from "src/view/components/MessageContext.tsx";
import {
    BaseError,
    useAccount,
    useEstimateGas,
    useReadContracts,
    useWaitForTransactionReceipt,
    useWriteContract
} from "wagmi";
import {networkList} from "src/hook/network.ts";
import {networkStatus} from "src/hook/networkStatus.ts";
export const Pledge = () => {
    const {address} = useGlobalState()
    const { showMessage, closeMessage } = useMessage()
    const tabList = ['Stake', 'Withdraw']
    const [tabIndex, setTabIndex] = useState(0 as number);
    const [recordsVisible, setRecordsVisible] = useState(false)
    const [userAmount, setUserAmount] = useState(0)
    const [label, setLabel] = useState('' as string)
    const { refetch: getStakingDepositRecord } = useStakingDepositRecord(address, 999999999, 999999999)
    const { refetch: getUserAmount } = useStakingUserAmount(address)
    const [depositRecord, setDepositRecord] = useState([] as DepositRecord[])
    const [positionIds, setPositionIds] = useState([] as number[])
    const [rewards, setRewards] = useState(0)
    const changeTab = (index: number) => {
        if (address) {
            setTabIndex(index)
        }
    }
    const openDialog = (label:string) => {
        setLabel(label)
        setRecordsVisible(true)
    }
    const getStakeUserAmount = () => {
        getUserAmount().then((res: any) => {
            if (res.isSuccess) {
                setUserAmount(parseFloat(formatEther(res.data)))
            }
        })
    }
    const getDepositRecord = () => {
        if (address) {
            getStakingDepositRecord().then((res: any) => {
                if (res.isSuccess) {
                    setDepositRecord(res.data)
                }
            })
        }
    }
    const { refetch: getPending } = useStakingPendings(positionIds)
    const getPendings = () => {
        if (address && positionIds.length) {
            stopPolling()
            getPending().then((res: any) => {
                startPolling()
                if (res.isSuccess) {
                    const iface = new ethers.Interface(stakingAbi)
                    const decode = res.data.map((item: string) => {
                        return iface.decodeFunctionResult('pending', item)
                    })
                    const bigNums = decode.map((item: any) => {
                        return item[0]
                    })
                    const total = bigNums.reduce((acc: bigint, item: bigint) => acc + item, 0n);
                    setRewards(parseFloat(formatEther(total)))
                }
            })
        }
    }
    const handleSuccessRefresh = () => {
        setTimeout(() => {
            getStakeUserAmount()
            getDepositRecord()
        }, 3000)
    }
    useEffect(() => {
        if (address) {
            getDepositRecord()
            getStakeUserAmount()
        }
    }, [address]);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const startPolling = () => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            getPendings();
        }, 5000);
    };
    const stopPolling = () => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };
    // claim reward
    const { data: claimHash, isPending: claimIsPending, writeContractAsync: claimWriteContract, isSuccess: claimIsSuccess } = useWriteContract()
    const claimLoading = useRef(false)
    const { isSuccess: claimAsyncIsSuccess,isFetched: claimAsyncIsFetched,isError} = useWaitForTransactionReceipt({
        hash: claimHash
    })
    useEffect(() => {
        if (isError) {
            closeMessage()
            claimLoading.current = false
            setGasParams(null)
            setGasLimit(0n)
            showMessage('Try Again')
        }
    }, [isError]);
    useEffect(() => {
        if (claimIsPending) {
            setTimeout(() => {
                showMessage('Claiming...',0)
            }, 200)
        } else if (!claimIsSuccess) {
            claimLoading.current = false
            setGasParams(null)
            setGasLimit(0n)
            closeMessage()
        }
    }, [claimIsPending]);
    const stakeRef:any = useRef<{ refresh: () => void }>(null);
    const claimSuccess = () => {
        setTimeout(() => {
            getPendings()
            showMessage('Successfully Claim')
            claimLoading.current = false
            stakeRef.current?.refreshBalance();
            setGasParams(null)
            setGasLimit(0n)
        }, 200)
    }
    useEffect(() => {
        if (claimAsyncIsSuccess) {
            claimSuccess()
        }
    }, [claimAsyncIsSuccess]);
    useEffect(() => {
        closeMessage()
        claimLoading.current = false
        setGasParams(null)
        setGasLimit(0n)
    }, [claimAsyncIsFetched]);
    const claimContract = () => {
        if (claimLoading.current) {
            const iface = new ethers.Interface(stakingAbi)
            const encode = positionIds.map((item) => {
                return iface.encodeFunctionData('claim', [item])
            })
            claimWriteContract({
                address: contractAddress,
                abi: stakingAbi,
                functionName: 'multicall',
                args: [encode],
                gasPrice: networkStatus ? 1000001234n : 5000001234n,
                gas: gasLimit
            })
                .catch((error) => {
                    if (error) {
                        const shortMessage = (error as BaseError)?.shortMessage;
                        if (shortMessage !== 'User rejected the request.') {
                            closeMessage()
                            if (shortMessage?.includes(':')) {
                                setTimeout(() => {
                                    showMessage(shortMessage?.split(':')[1].trim())
                                }, 100)
                            } else {
                                setTimeout(() => {
                                    showMessage(shortMessage)
                                }, 100)
                            }
                        }
                    }
                    claimLoading.current = false
                    setGasParams(null)
                    setGasLimit(0n)
                })
        }
    }
    // gas computed
    const account = useAccount()
    const claimGasComputed = () => {
        const targetId = networkList[networkStatus].id
        if (targetId !== account?.chainId) {
            showMessage('Wrong Network')
            return
        }
        if (!claimLoading.current && positionIds.length) {
            claimLoading.current = true
            const iface = new ethers.Interface(stakingAbi)
            const encode = positionIds.map((item) => {
                return iface.encodeFunctionData('claim', [item])
            })
            const data: any = iface.encodeFunctionData('multicall', [encode])
            setGasParams({
                data: data
            })
        }
    }
    const [gasParams, setGasParams] = useState<any | null>(null)
    const estimateGas = useEstimateGas({
        to: contractAddress,
        data: gasParams?.data
    })
    const [gasLimit, setGasLimit] = useState<bigint>(0n)
    useEffect(() => {
        if (gasLimit !== 0n) {
            claimContract()
        }
    }, [gasLimit]);
    useEffect(() => {
        if (gasParams !== null) {
            estimateGas.refetch().then((res: any) => {
                if (res.isSuccess) {
                    const newGas = parseInt((parseInt(res.data.toString()) * 1.3).toString())
                    setGasLimit(BigInt(newGas))
                }
            })
        }
    }, [gasParams]);
    useEffect(() => {
        if (depositRecord.length) {
            const ids = depositRecord.filter((record) => record.share !== 0n).map((item) => {
                return Number(item.position_index)
            })
            setPositionIds(ids)
        } else {
            setRewards(0)
            setPositionIds([])
        }
    }, [depositRecord]);
    useEffect(() => {
        if (positionIds.length) {
            getPendings()
        }
    }, [positionIds]);
    const toStakeDes = () => {
        const url = 'https://medium.com/@cellulalifegame/68a480cb2d32'
        window.open(url, '_blank')
    }
    return (
        <div className={'ff-SnasmBook pledge-wrap'}>
            <div className={'left'}>
                <div className={'tab-wrap'}>
                    {
                        tabList.map((tab, index) => (
                            <div onClick={() => changeTab(index)} key={index} className={`single-tab ${index === tabIndex ? 'tab-active' : 'tab-normal' }`}>
                                {tab}
                                {
                                    !address && index === 1 && <img src={lock} alt=""/>
                                }
                            </div>
                        ))
                    }
                </div>
                <div className={'content'}>
                    {
                        tabIndex === 0 && <Stake successRefresh={handleSuccessRefresh} ref={stakeRef} ></Stake>
                    }
                    {
                        tabIndex === 1 && <Withdraw successRefresh={handleSuccessRefresh}></Withdraw>
                    }
                </div>
            </div>
            <div className={'right'}>
                <div className={'title-wrap'}>
                    <img src={titleIcon} alt=""/>
                    <div className={'title'}>Portfolio</div>
                </div>
                <div className={'current-stake'}>
                    <div className={'left'}>
                        <div className={'label'}>Current Staked</div>
                        <div className={'value'}>{handlePermillage(truncateNumber(userAmount, 8))} CELA</div>
                    </div>
                    {/*<img onClick={() => openDialog('Earnings Withdrawal Record')} className={'cursor-pointer'} src={rightArrow} alt=""/>*/}
                </div>
                <div className={'staking-rewards'}>
                    <div className={'left'}>
                        <div className={'label'}>Staking Rewards</div>
                        <div className={'value'}>{handlePermillage(truncateNumber(rewards, 8, true))} CELA</div>
                        <div onClick={claimGasComputed} className={`show-flex-box-r show-flex-center cursor-pointer claim-btn ${!address ? 'btn-disabled' : '' }`}>Claim</div>
                    </div>
                    <img onClick={() => openDialog('Withdraw Record')} className={'cursor-pointer'} src={rightArrow} alt=""/>
                </div>
                <div className={'show-flex-box-r show-flex-center cursor-pointer stake-des'} onClick={toStakeDes}>
                    What's CELA Staking Pool?
                </div>
            </div>
            {
                recordsVisible && <Records title={label} closeVisible={() => setRecordsVisible(false)}></Records>
            }
        </div>
    )
}