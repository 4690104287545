// src/contexts/CopyContext.tsx
import React, { createContext, ReactNode, useContext } from 'react';
import { useMessage } from "src/view/components/MessageContext.tsx";

interface CopyContextProps {
    copy: (text: string) => void;
}

const CopyContext = createContext<CopyContextProps | undefined>(undefined);

const CopyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { showMessage } = useMessage()
    const copy = (text: string) => {
        navigator.clipboard.writeText(text).then(
            () => {
                showMessage('Successfully Copy')
                console.log('Text copied to clipboard');
            },
            (err) => {
                console.error('Could not copy text: ', err);
            }
        );
    };

    return (
        <CopyContext.Provider value={{ copy }}>
            {children}
        </CopyContext.Provider>
    );
};

const useCopy = (): CopyContextProps => {
    const context = useContext(CopyContext);
    if (!context) {
        throw new Error('useCopy must be used within a CopyProvider');
    }
    return context;
};

export { CopyProvider, useCopy };
