import "./releaseRule.scss"
import {useNavigate} from "react-router-dom";
import {useGlobalState} from "src/hook/useGlobalState.ts";
import {useWeb3Modal} from '@web3modal/wagmi/react'
import {usePlayerDetail} from 'src/hook/useAirDropRead.tsx'
import {useEffect} from "react";

export const ReleaseRule = () => {
    const navigate = useNavigate()
    const {address} = useGlobalState()
    const {open} = useWeb3Modal()
    const airDropList = [
        {
            title: 'Energy Points',
            currentUnlock: '50% Unlocks at TGE',
            followUpUnlock: '25% Unlocks every 30 days',
            totalUnlock: 'Until 100% unlock'
        },
        {
            title: 'BitCell NFT',
            currentUnlock: '10% Unlocks at TGE',
            followUpUnlock: '15% Unlocks every 30 days',
            totalUnlock: 'Until 100% unlock'
        },
        {
            title: 'Airdrop',
            currentUnlock: '10% Unlocks at TGE',
            followUpUnlock: '15% Unlocks every 30 days',
            totalUnlock: 'Until 100% unlock'
        },
        {
            title: 'Life Points',
            currentUnlock: '10% Unlocks at TGE',
            followUpUnlock: '15% Unlocks every 30 days',
            totalUnlock: 'Until 100% unlock'
        },
    ]
    const viewDetail = () => {
        navigate('/airdrop-list')
    }
    const toBitCell = () => {
        navigate('/bitcell-airdrop')
    }


    return (
        <div className={'ff-SnasmBook release-rule-page'}>
            <div className={'title'}>Dear Player</div>
            <div
                className={'color-white des'}>To foster the sustainable development of the Cellula ecosystem the unlocking of $CELA will follow this plan.
            </div>
            <div className="air-drop-list">
                {
                    airDropList.map((airDrop: any, index: number) => (
                        <div className={"fs-0 single-air-drop"} key={index}>
                            <div className={"color-white air-drop-title"}>{airDrop.title}</div>
                            <div className={"show-flex-box-r show-flex-sb color-line"}>
                                <div className={"color-line-1"}></div>
                                <div className={"color-line-2"}></div>
                                <div className={"color-line-3"}></div>
                            </div>
                            <div className={"unlock-value"}>
                                <div className={'single-column'}>
                                    <div>{airDrop.currentUnlock}</div>
                                </div>
                                <div className={'single-column second-des'}>
                                    <div>{airDrop.followUpUnlock}</div>
                                </div>
                                <div className={'single-column'}>
                                    <div>{airDrop.totalUnlock}</div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className={'btn-wrap'}>
                <div onClick={toBitCell}
                     className={'show-flex-box-r show-flex-center color-white cursor-pointer view-bitcell-btn'}>Check
                    BitCell Airdrop
                </div>
                {
                    address && <div onClick={viewDetail}
                                    className={'show-flex-box-r show-flex-center cursor-pointer connect-wallet-btn'}>View
                        Now</div>
                }
                {
                    !address && <div onClick={() => open()}
                                     className={'show-flex-box-r show-flex-center cursor-pointer connect-wallet-btn'}>Connect
                        Wallet</div>
                }
            </div>
            <div className={'show-flex-box-r show-flex-center warning'}>Note: The BitCell airdrop on the BTC
                network requires a separate claim.
            </div>
        </div>
    )
}