// MessageContext.tsx
import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import Message from "./Message.tsx";

interface MessageContextType {
    showMessage: (message: string, duration?: number) => void;
    closeMessage: () => void;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined);

export const useMessage = (): MessageContextType => {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error('useMessage must be used within a MessageProvider');
    }
    return context;
};

export const MessageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [message, setMessage] = useState<string | null>(null);
    const [duration, setDuration] = useState<number | null>(null);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const showMessage = useCallback((message: string, duration: number = 3000) => {
        message === 'The total cost (gas * gas fee + value) of executing this transaction exceeds the balance of the account.' ?
            setMessage('Insufficient Balance'):
            setMessage(message);
        setDuration(duration)
        if (duration !== 0) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            const id = setTimeout(() => {
                setMessage(null);
                setDuration(null)
            }, duration);
            setTimeoutId(id);
        }
    }, [timeoutId]);

    const closeMessage = useCallback(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setMessage(null);
        setDuration(null)
    }, [timeoutId]);

    return (
        <MessageContext.Provider value={{ showMessage, closeMessage }}>
            {children}
            {message && <Message message={message} duration={duration} onClose={closeMessage} />}
        </MessageContext.Provider>
    );
};
