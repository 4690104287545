// GlobalStateContext.tsx
import { createContext } from 'react';

interface GlobalState {
    isWhiteList: boolean;
    netWorkStatus: number,
    setNetWorkStatus: (value: number) => void,
    setIsWhiteList: (value: boolean) => void;
    address: string;
    setAddress: (value: string | any) => void
    musicStatus: boolean
    setMusicStatus: (value: boolean) => void
    btcAddress: string;
    setBtcAddress: (value: string | any) => void
    isSafePal: boolean;
    setIsSafePal: (value: boolean) => void;
    isStart: boolean | null;
    setIsStart: (value: boolean) => void
    goldLoop: boolean
    setGoldLoop: (value: boolean) => void
    goldRecord: boolean
    setGoldRecord: (value: boolean) => void
    previousPath: string
    setPreviousPath: (value: string) => void
    setBtcPrice: (value: number) => void
    btcPrice: number,
    isLoading: boolean,
    setIsLoading: (value: boolean) => void
}

export const GlobalStateContext = createContext<GlobalState | undefined>(undefined);