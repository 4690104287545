import "./airDropList.scss"
import React, {useEffect, useRef, useState} from "react";
import {AirDropDetail} from "./airDropDetail";
import {User} from "src/api";
import {contractAbiMap, ContractAbiTypeEnum} from "src/enums/contractAbiEnum.ts";
import {networkList} from "src/hook/network.ts";
import {networkStatus} from "src/hook/networkStatus.ts";
import {useGlobalState} from "src/hook/useGlobalState.ts";
import {useMessage} from "src/view/components/MessageContext.tsx";
import {
    BaseError,
    useWaitForTransactionReceipt,
    useAccount,
    useWriteContract,
    useReadContract,
    useEstimateGas
} from "wagmi";
import {useNavigate} from "react-router-dom";

const abi = JSON.parse(contractAbiMap.get(ContractAbiTypeEnum.AIR_DROP) as string)
const contractAddress: any = networkList[networkStatus].airDrop
import {useStakingDurations, useStakingRewardPerBlock, useStakingTotalShare} from "src/hook/useStake.ts";
import {
    useGetStartTime,
    useClaimable,
    usePlayerAirDrop,
    usePlayerDetail,
    usePlayerStepsInfo,
    useExchangeLogs
} from 'src/hook/useAirDropRead.tsx'

import {formatEther, parseEther} from "viem";

import cellIcon from 'img/airDropList/cell-icon.png'
import upArrow from 'img/airDropList/up-arrow.png'
import downArrow from 'img/airDropList/down-arrow.png'
import goldCoin from 'img/airDropList/gold-coin.png'
import {calculateRemainingTime, truncateNumber, handlePermillage} from "src/utils/utils.ts";
import {NoData} from "src/view/components/noData.tsx";
import {ethers} from "ethers";
import {log} from "echarts/types/src/util/log.js";

const findCurrentPeriods = async (arr: any) => {
    const res: any = await User.getTime()
    if (res.code === 200) {
        if (Number(arr[arr.length - 1]) * 1000 < Number(res.data)) {
            return 7
        }
        const periodIndex = arr.findIndex((time: any) => {
            return Number(time) * 1000 > Number(res.data)
        });
        return periodIndex
    }
}

export const AirDropList = () => {
    const [stakeTime, setStakeTime] = useState(7776000)
    const { refetch: getStakingTotalShare } = useStakingTotalShare()
    const { refetch: getRewardPerBlock } = useStakingRewardPerBlock()
    const { refetch: getStakingDurations } = useStakingDurations(stakeTime)
    const [totalShare, setTotalShare] = useState(0)
    const [blockReward, setBlockReward] = useState(0)
    const [weight, setWeight] = useState(0)
    const [aprValue, setAprValue] = useState(0 as number)

    const navigate = useNavigate()
    const {address, isLoading, setIsLoading} = useGlobalState()
    const account = useAccount()
    const {showMessage, closeMessage} = useMessage()
    const [steps, setSteps] = useState([0, 1, 2, 3, 4, 5, 6]);
    const [pageStatus, setPageStatus] = useState(0 as number);
    const airDropListTab = ['', 'Quantity I Hold', 'Amount of CELA Received']
    // status状态，0待解锁，1当前解锁，2已解锁, isUnlock是否展开
    const [myAirDropInfo, setMyAirDropInfo] = useState([] as any)
    const [airDropList, setAirDropList] = useState([] as any)
    const [currentPeriod, setCurrentPeriod] = useState(0 as number)
    const [firstCellToken, setFirstCellToken] = useState(0 as number)
    const [airdropDetailVisible, setAirdropDetailVisible] = useState<boolean>(false)
    const [currentClaim, setCurrentClaim] = useState(0 as any)
    const [isStart, setIsStart] = useState<boolean>(false)

    // 获取玩家的兑换结果（适用于 Cliam 之后）
    // const exchangeLogs: any = useExchangeLogs(address, 0);
    // useEffect(() => {
    //     if (exchangeLogs.isSuccess) {
    //     }
    // }, [exchangeLogs.isSuccess]);
    const capitalizeEachWord = (str: any) => {
        return str
            .split(' ') // 按空格分割字符串
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)) // 转换首字母为大写
            .join(' '); // 将单词重新组合成字符串
    }
    const judgeIsClaim = (data: any, current?: number) => {
        const isClaim = data[2].findIndex((item: any) => {
            return item === true
        })
        if (isClaim === -1) {
            setPageStatus(1)
            const totalCell = data[0][0].reduce((accumulator: any, current: any) => {
                return accumulator + Number(formatEther(current))
            }, 0)
            setFirstCellToken(totalCell)
        } else {
            setPageStatus(2)
            findCurrentPeriods(data[1]).then((res: any) => {
                const arr = data[0].map((item: any, index: number) => {
                    return {
                        status: data[2][index] ? 2 : index < res ? 1 : 0,
                        value: truncateNumber(Number(formatEther(item[0])) + Number(formatEther(item[1])) + Number(formatEther(item[2])), 8),
                        isUnlock: false,
                        time: calculateRemainingTime(Number(data[1][index]) * 1000)
                    }
                })
                if (current !== undefined) {
                    arr[current].isUnlock = true
                } else {
                    const index = arr.findIndex((item: any) => item.status === 1)
                    if (index !== -1) {
                        arr[index].isUnlock = true
                    }
                }
                setAirDropList(arr)
            }).catch(err => {
            })
        }
    }
    // 获取玩家解锁信息（适用于 Cliam 之前）
    const {data: playerStepsInfo, isSuccess: playerStepsInfoSuccess, refetch} = usePlayerStepsInfo(address, steps)
    useEffect(() => {
        if (playerStepsInfoSuccess) {
            setTimeout(() => {
                setIsLoading(false)
            }, 200)
            judgeIsClaim(playerStepsInfo)
        }
    }, [playerStepsInfoSuccess]);

    // 获取平台
    const startTimeInfo: any = useGetStartTime();
    const startTime = useRef(0)
    useEffect(() => {
        if (startTimeInfo.isSuccess) {
            // startTime.current = Number(startTimeInfo.data) * 1000
            const handleData = async () => {
                const res: any = await User.getTime()
                if (res.code === 200) {
                    if (Number(res.data) >= Number(startTimeInfo.data) * 1000) {
                        setIsStart(true)
                    }
                }
            }
            handleData()
        }
    }, [startTimeInfo.isSuccess]);

    // 获取玩家持有的资产数量
    // const playerAirDrop: any = usePlayerAirDrop(address);
    // useEffect(() => {
    //     if (playerAirDrop.isSuccess) {
    //         setMyAirDropInfo(playerAirDrop.data)
    //     }
    // }, [playerAirDrop]);

    // 获取玩家空投详情
    const playerDetail: any = usePlayerDetail(address);
    useEffect(() => {
        if (pageStatus === 1 && playerDetail.isSuccess) {
            const totalCell = playerDetail.data[1].reduce((accumulator: any, current: any) => {
                return accumulator + Number(formatEther(current));
            }, 0);
            const arr = [
                {
                    label: 'Energy Points',
                    num: formatEther(playerDetail.data[0][0]),
                    cellNum: formatEther(playerDetail.data[1][0]),
                    unit: 'Energy',
                    currentUnlock: '50% Unlocks at TGE',
                    followUpUnlock: '25% Unlocks every 30 days',
                    totalUnlock: 'Until 100% unlock'
                },
                {
                    label: 'BitCell NFT',
                    num: 'Claim the BitCell airdrop on a designated page',
                    cellNum: '',
                    currentUnlock: '10% Unlocks at TGE',
                    followUpUnlock: '15% Unlocks every 30 days',
                    totalUnlock: 'Until 100% unlock'
                },
                {
                    label: 'Airdrop',
                    num: formatEther(playerDetail.data[0][1]),
                    cellNum: formatEther(playerDetail.data[1][1]),
                    unit: 'CELA',
                    currentUnlock: '10% Unlocks at TGE',
                    followUpUnlock: '15% Unlocks every 30 days',
                    totalUnlock: 'Until 100% unlock'
                },
                {
                    label: 'Life Points',
                    num: formatEther(playerDetail.data[0][2]),
                    cellNum: formatEther(playerDetail.data[1][2]),
                    unit: 'Life Points',
                    currentUnlock: '10% Unlocks at TGE',
                    followUpUnlock: '15% Unlock every 30 days',
                    totalUnlock: 'Until 100% unlock'
                },
                // {label: 'Total Received', num: '', cellNum: totalCell},
            ];
            setMyAirDropInfo(arr);
        }
    }, [pageStatus, playerDetail.isSuccess]);

    const claimLoading = useRef(false)
    const currentStep = useRef(0)
    const claimApi = (step: number) => {
        const targetId = networkList[networkStatus].id
        if (targetId !== account?.chainId) {
            showMessage('Wrong Network')
            return
        }
        if (!claimLoading.current) {
            setCurrentClaim(step)
            currentStep.current = step
            claimLoading.current = true
            showMessage('Claiming...', 0)
            claimGasComputed()
        }
    }
    const {data: hash, isPending, writeContractAsync, isSuccess: hashSuccess, error} = useWriteContract()
    const claimContract = (step: number) => {
        writeContractAsync({
            address: contractAddress,
            abi: abi,
            functionName: 'claim',
            args: [step],
            gasPrice: networkStatus ? 1000001234n : 5000001234n,
            gas: gasLimit
        }).catch(async (error: any) => {
            claimLoading.current = false
            setGasParams(null)
            setGasLimit(0n)
            if (error) {
                const shortMessage = (error as BaseError)?.shortMessage;
                closeMessage()
                const res: any = await refetch()
                judgeIsClaim(res.data)
                if (shortMessage !== 'User rejected the request.') {
                    if (shortMessage?.includes(':')) {
                        setTimeout(() => {
                            showMessage(shortMessage?.split(':')[1].trim())
                        }, 100)
                    } else {
                        setTimeout(() => {
                            showMessage(shortMessage)
                        }, 100)
                    }
                }
            }
        })
    }
    const {isSuccess, isFetched, data, isError} = useWaitForTransactionReceipt({
        hash
    })
    useEffect(() => {
        if (isError) {
            const handleError = async () => {
                closeMessage()
                claimLoading.current = false
                setGasParams(null)
                setGasLimit(0n)
                const res: any = await refetch()
                judgeIsClaim(res.data)
            }
            handleError()
        }
    }, [isError]);
    useEffect(() => {
        const handleSuccess = async () => {
            if (isSuccess) {
                claimLoading.current = false
                setGasParams(null)
                setGasLimit(0n)
                closeMessage()
                showMessage('Successfully Claimed')
                setPageStatus(2)
                const res: any = await refetch()
                judgeIsClaim(res.data, currentClaim)
            }
        };
        handleSuccess();
    }, [isSuccess]);
    useEffect(() => {
        if (isPending) {
            // showMessage('Claim...', 0)
        } else if (!hashSuccess) {
            closeMessage()
            claimLoading.current = false
            setGasParams(null)
            setGasLimit(0n)
        }
    }, [isPending]);
    useEffect(() => {
        closeMessage()
        claimLoading.current = false
        setGasParams(null)
        setGasLimit(0n)
    }, [isFetched]);

    const unfold = (type: number, index: number) => {
        // type 1为打开, -1为折叠
        const updatedAirDropList = airDropList.map((item: any, i: number) => {
            return {
                ...item,
                isUnlock: type === 1 && i === index
            };
        });
        setAirDropList(updatedAirDropList)
    }

    const getDurations = () => {
        getStakingDurations().then((res: any) => {
            if (res.data) {
                setWeight(Number(res.data[1]) / 10000)
            }
        })
    }
    const getTotalShare = () => {
        getStakingTotalShare().then((res: any) => {
            if (res.data) {
                setTotalShare(parseFloat(formatEther(res.data)))
            }
        })
    }
    const getBlockReward = () => {
        getRewardPerBlock().then((res: any) => {
            if (res.data) {
                setBlockReward(parseFloat(formatEther(res.data)))
            }
        })
    }

    useEffect(() => {
        getDurations()
    }, [stakeTime]);
    useEffect(() => {
        setIsLoading(true)
        getTotalShare()
        getBlockReward()
        getDurations()

        // const fetchTimeData = async () => {
        //     try {
        //         const res: any = await User.getTime();
        //         if (res.code === 200) {
        //             if (startTime.current > 0 && Number(res.data) >= startTime.current) {
        //                 setIsStart(true);
        //             }
        //         }
        //     } catch (error) {
        //         console.error('Error fetching time:', error);
        //     }
        // };
        // fetchTimeData();
        // // 设置定时器，5秒间隔执行
        // const timer = setInterval(fetchTimeData, 5000);
        // // 清理定时器
        // return () => {
        //     clearInterval(timer); // 清除 setInterval
        // };
    }, []);
    useEffect(() => {
        APRCompute()
    }, [weight, totalShare, blockReward]);
    const APRCompute = () => {
        const value = (10*weight/((10*weight)+totalShare)*(blockReward*28800))*365/10*100
        setAprValue(value)
    }

    const viewDetail = () => {
        setAirdropDetailVisible(true)
    }

    const toStake = () => {
        navigate('/staking', { state: { fromAirdrop: true } })
    }

    const toBourse = (type: string) => {
        const url = type === 'gate' ? 'https://www.gate.io/' : 'https://www.lbank.com/'
        window.open(url, '_blank')
    }

    // gas computed
    const claimGasComputed = () => {
        const iface = new ethers.Interface(abi)
        const data: any = iface.encodeFunctionData('claim', [currentStep.current])
        setGasParams({
            data: data
        })
    }
    const [gasParams, setGasParams] = useState<any | null>(null)
    const estimateGas = useEstimateGas({
        to: contractAddress,
        data: gasParams?.data
    })
    const [gasLimit, setGasLimit] = useState<bigint>(0n)
    useEffect(() => {
        if (gasLimit !== 0n) {
            claimContract(currentStep.current)
        }
    }, [gasLimit]);
    useEffect(() => {
        if (gasParams !== null) {
            estimateGas.refetch().then((res: any) => {
                if (res.isSuccess) {
                    const newGas = parseInt((parseInt(res.data.toString()) * 1.3).toString())
                    setGasLimit(BigInt(newGas))
                }
            }).catch(err => {
            })
        }
    }, [gasParams]);
    return (
        <div className={'ff-SnasmBook air-drop-list-wrap'}>
            {
                !isLoading &&
                <div>
                    {
                        pageStatus === 1 &&
                        <div className={'step1'}>
                            <div className={'assets-list'}>
                                {
                                    myAirDropInfo.map((item: any, i: number) => (
                                        <div key={i} className={'single-assets'}>
                                            <div className={'title'}>{item.label}</div>
                                            {
                                                item.label === 'BitCell NFT' ?
                                                    <div className={'value-box-bitcell'}>
                                                        {item.num}
                                                    </div>
                                                    :
                                                    <div className={'value-box'}>
                                                        <div>
                                                            <div className={'left'}>My Holding</div>
                                                            <div
                                                                className={'right-bottom'}>{handlePermillage(truncateNumber(item.num, 8))} {item.unit}</div>
                                                        </div>
                                                        <div>
                                                            <div className={'left'}>Total CELA</div>
                                                            <div
                                                                className={'right-top'}>{handlePermillage(truncateNumber(item.cellNum, 8))} CELA
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            <div className={"show-flex-box-r show-flex-sb color-line"}>
                                                <div className={"color-line-1"}></div>
                                                <div className={"color-line-2"}></div>
                                                <div className={"color-line-3"}></div>
                                            </div>
                                            <div className={"unlock-value"}>
                                                <div className={'single-column'}>
                                                    <div>{item.currentUnlock}</div>
                                                </div>
                                                <div className={'single-column'}>
                                                    <div>{item.followUpUnlock}</div>
                                                </div>
                                                <div className={'single-column'}>
                                                    <div>{item.totalUnlock}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className={'current-unlock'}>
                                <div className={'first-period'}>
                                    <div className={'title'}>
                                        <div className={'color-white label'}>{isStart ? 'Current Unlocking' : 'To Be Unlocked'}</div>
                                    </div>
                                    <div className={'content'}>
                                        <div className={'left'}>
                                            <img className={'icon'} src={cellIcon} alt=""/>
                                            <div className={'cell-token-label'}>CELA</div>
                                            <div
                                                className={'ff-Snasm letter-spacing-1 value'}>{handlePermillage(truncateNumber(firstCellToken, 8))}</div>
                                        </div>
                                        <div
                                            onClick={() => claimApi(0)}
                                            className={`show-flex-box-r show-flex-center color-white cursor-pointer right-btn ${firstCellToken === 0 || !isStart ? 'btn-disabled' : ''}`}>
                                            Claim
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {
                        pageStatus === 2 &&
                        <div className={'step2'}>
                            {
                                airDropList.length > 0 ?
                                    <div>
                                        {
                                            airDropList.map((item: any, index: number) => (
                                                <div className={'single-box'} key={index}>
                                                    {
                                                        item.status === 2 ?
                                                            <div className={'claimed'}>
                                                                {
                                                                    item.isUnlock ?
                                                                        <div className={'show-flex-box-c align-center unlock'}>
                                                                            <div
                                                                                className={'congratulation'}>Congratulations
                                                                            </div>
                                                                            <div className={'show-flex-box-c align-center'}>
                                                                                {/*<div className={'color-white des'}>successfully*/}
                                                                                {/*    claiming an*/}
                                                                                {/*    airdrop of*/}
                                                                                {/*</div>*/}
                                                                                <div
                                                                                    className={'ff-Snasm letter-spacing-1 cell-value'}>{handlePermillage(item.value)} CELA
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={'show-flex-box-c align-center address'}>
                                                                                <div className={'color-white'}>have been
                                                                                    successfully airdropped to your wallet:
                                                                                </div>
                                                                                <div className={'color-white'}> {address}</div>
                                                                                {/*<div className={'color-white'}>Sent to*/}
                                                                                {/*    wallet&nbsp;:&nbsp;</div>*/}
                                                                                {/*<div className={'color-white'}> {address}</div>*/}
                                                                            </div>
                                                                            <div className={'btn-wrap'}>
                                                                                <div
                                                                                    onClick={viewDetail}
                                                                                    className={'show-flex-box-r show-flex-center color-white cursor-pointer view-btn'}>Check
                                                                                    Airdrop Details
                                                                                </div>
                                                                                <div
                                                                                    onClick={toStake}
                                                                                    className={'show-flex-box-c align-center color-white cursor-pointer pledge-btn'}>
                                                                                    <div className={'recommend'}>Recommend</div>
                                                                                    <div className={'btn-word'}>Stake for 3 months at an</div>
                                                                                    <div className={'btn-word'}>
                                                                                        {/*<span>3X&nbsp;</span>*/}
                                                                                        {/*boosted APR of*/}
                                                                                        APR up to
                                                                                        <span>&nbsp;{handlePermillage(truncateNumber(aprValue).toFixed(2))}%</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={'bourse-title'}>Discover
                                                                                more in CEXs
                                                                            </div>
                                                                            <div
                                                                                className={'show-flex-box-r show-flex-sa bourse'}>
                                                                                <div
                                                                                    className={'show-flex-box-c show-flex-center label'}>
                                                                                    <div>Discover more in CEXs</div>
                                                                                </div>
                                                                                <div className={'single-bourse bourse-1'}>
                                                                                    <div className={'icon'} onClick={() => toBourse('gate')}></div>
                                                                                </div>
                                                                                <div className={'single-bourse bourse-2'}>
                                                                                    <div className={'icon'} onClick={() => toBourse('lbank')}></div>
                                                                                </div>
                                                                                {/*<div className={'single-bourse bourse-3'}>*/}
                                                                                {/*    <div className={'icon'}></div>*/}
                                                                                {/*    <div className={'label'}>gate.io</div>*/}
                                                                                {/*</div>*/}
                                                                                {/*<div className={'single-bourse bourse-4'}>*/}
                                                                                {/*    <div className={'icon'}></div>*/}
                                                                                {/*    <div className={'label'}></div>*/}
                                                                                {/*</div>*/}
                                                                            </div>
                                                                            <img className={'cursor-pointer up-arrow'}
                                                                                 onClick={() => unfold(-1, index)} src={upArrow}
                                                                                 alt=""/>
                                                                        </div>
                                                                        :
                                                                        <div
                                                                            className={'not-unlock'}>
                                                                            <div className={'title claimed-title'}>
                                                                                <div className={'color-white label'}>Claimed
                                                                                </div>
                                                                            </div>
                                                                            <div className={'content'}>
                                                                                <div className={'not-unlock-left left'}>
                                                                                    <div
                                                                                        className={'show-flex-box-r align-center'}>
                                                                                        <img className={'icon'} src={cellIcon}
                                                                                             alt=""/>
                                                                                        <div
                                                                                            className={'color-white cell-token-label'}>CELA
                                                                                        </div>
                                                                                        <div
                                                                                            className={'unlocked-value'}>{handlePermillage(item.value)}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={'show-flex-box-r right'}>
                                                                                    <div
                                                                                        onClick={viewDetail}
                                                                                        className={'show-flex-box-r show-flex-center color-white cursor-pointer view-detail'}>View
                                                                                        Details
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <img className={'cursor-pointer down-arrow'}
                                                                                 onClick={() => unfold(1, index)}
                                                                                 src={downArrow}
                                                                                 alt=""/>
                                                                        </div>
                                                                }
                                                            </div>
                                                            : item.status === 1 ?
                                                                <div className={'claim'}>
                                                                    {
                                                                        item.isUnlock ?
                                                                            <div
                                                                                className={'show-flex-box-c align-center pt-20 unlock'}>
                                                                                <img className={'gold-coin'} src={goldCoin} alt=""/>
                                                                                <div className={'des'}>
                                                                                    <div className={'color-white'}>You have</div>
                                                                                    <div
                                                                                        className={'ff-Snasm letter-spacing-1 cell-value'}>{handlePermillage(item.value)} CELA
                                                                                    </div>
                                                                                    <div className={'color-white'}>available to claim
                                                                                    </div>
                                                                                </div>
                                                                                <div className={'show-flex-box-r btn-wrap'}>
                                                                                    <div
                                                                                        onClick={viewDetail}
                                                                                        className={'show-flex-box-r show-flex-center color-white cursor-pointer view-btn'}>Check Airdrop Details
                                                                                    </div>
                                                                                    <div
                                                                                        onClick={() => claimApi(index)}
                                                                                        className={'show-flex-box-r show-flex-center color-white cursor-pointer pledge-btn'}>Claim CELA
                                                                                    </div>
                                                                                </div>
                                                                                <img className={'cursor-pointer up-arrow'}
                                                                                     onClick={() => unfold(-1, index)} src={upArrow}
                                                                                     alt=""/>
                                                                            </div>
                                                                            :
                                                                            <div className={'not-unlock'}>
                                                                                <div className={'title'}>
                                                                                    <div className={'color-white label'}>Current
                                                                                        Unlocking
                                                                                    </div>
                                                                                </div>
                                                                                <div className={'content'}>
                                                                                    <div className={'left'}>
                                                                                        <div
                                                                                            className={'show-flex-box-r align-center'}>
                                                                                            <img className={'icon'}
                                                                                                 src={cellIcon}
                                                                                                 alt=""/>
                                                                                            <div
                                                                                                className={'color-white cell-token-label'}>CELA
                                                                                            </div>
                                                                                            {/*<div*/}
                                                                                            {/*    className={'show-flex-box-r show-flex-center status-1'}>Current*/}
                                                                                            {/*    Unlock*/}
                                                                                            {/*</div>*/}
                                                                                        </div>
                                                                                        <div
                                                                                            className={'ff-Snasm letter-spacing-1 value'}>{handlePermillage(item.value)}</div>
                                                                                    </div>
                                                                                    <div
                                                                                        onClick={() => unfold(1, index)}
                                                                                        className={'show-flex-box-r show-flex-center color-white cursor-pointer right-btn'}>
                                                                                        Claim
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                :
                                                                <div className={'cannot-claim'}>
                                                                    <div className={'title'}>
                                                                        <div className={'color-white label'}>To Be Unlocked</div>
                                                                    </div>
                                                                    <div className={'content'}>
                                                                        <div className={'left'}>
                                                                            <div className={'show-flex-box-r align-center'}>
                                                                                <img className={'icon'} src={cellIcon} alt=""/>
                                                                                <div
                                                                                    className={'color-white cell-token-label'}>CELA
                                                                                </div>
                                                                                {/*<div*/}
                                                                                {/*    className={'show-flex-box-r show-flex-center status-0'}>Unlock*/}
                                                                                {/*    Pending*/}
                                                                                {/*</div>*/}
                                                                            </div>
                                                                            <div
                                                                                className={'ff-Snasm letter-spacing-1 value'}>{handlePermillage(item.value)}</div>
                                                                        </div>
                                                                        <div className={'color-white right-time'}>
                                                                            {item.time} Unlock
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className={'w100 h100'}>
                                        {/*<NoData></NoData>*/}
                                    </div>
                            }
                        </div>
                    }
                    {/*{*/}
                    {/*    pageStatus === 0 &&*/}
                    {/*    <div className={'w100 h100'}>*/}
                    {/*        <NoData></NoData>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            }
            {
                airdropDetailVisible && <AirDropDetail closeVisible={() => setAirdropDetailVisible(false)}/>
            }
        </div>
    )
}