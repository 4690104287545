import "./airDropDetail.scss"
import {usePlayerDetail, usePlayerStepsInfo} from 'src/hook/useAirDropRead.tsx'
import {useGlobalState} from "src/hook/useGlobalState.ts";
import React, {useEffect, useState} from "react";
import {formatEther} from "viem";
import {User} from "src/api";
import {formatTimestampToDate, truncateNumber, handlePermillage} from "src/utils/utils.ts";
import {NoData} from "src/view/components/noData.tsx";

interface airDropDetailProps {
    closeVisible: () => void
}

const unlockTabList = ['', 'My Holding', 'Current Unclock']
const notUnlockTabList = ['', 'Unlock Percentage', 'Upcoming Unlocks', 'Remaining Unlocks']
const findCurrentPeriods = async (arr: any) => {
    const res: any = await User.getTime()
    if (res.code === 200) {
        const periodIndex = arr.findIndex((time: any) => {
            return Number(time) * 1000 > Number(res.data)
        });
        return periodIndex
    }
}

const sumElements = (arr: any) => {
    // 初始化一个数组来保存三个和
    const sums = [0, 0, 0];
    // 遍历每个子数组
    arr.forEach((subArray:any) => {
        // 对每个元素进行累加，如果存在
        if (subArray[0] !== undefined) sums[0] += Number(formatEther(subArray[0]));
        if (subArray[1] !== undefined) sums[1] += Number(formatEther(subArray[1]));
        if (subArray[2] !== undefined) sums[2] += Number(formatEther(subArray[2]));
    });
    return sums; // 返回结果数组
}

const getTotal = (arr: any) => {
    const total = arr.reduce((accumulator: any, current: any) => {
        return accumulator + current;
    }, 0)
    return total
}

export const AirDropDetail: React.FC<airDropDetailProps> = function ({closeVisible}) {
    const {address, isLoading, setIsLoading} = useGlobalState()
    // const [list, setList] = useState([] as any)

    const [stepInfo, setStepInfo] = useState(null as any)
    const [bitCellInfo, setBitCellInfo] = useState(null as any)
    const [totalInfo, setTotalInfo] = useState(null as any)

    const [data, setData] = useState([] as any)

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            // const res: any = await User.getBitcellList(address)
            const res: any = await User.getBitcellList(address)
            if (res.code === 200) {
                setIsLoading(false)
                setBitCellInfo(res.data || [])
            }
        };

        fetchData();
    }, []); // 依赖为空数组，表示只在组件挂载时执行

    // 获取玩家空投详情
    const playerDetail: any = usePlayerDetail(address);
    useEffect(() => {
        if (playerDetail.isSuccess) {
            const totalCell = playerDetail.data[1].reduce((accumulator: any, current: any) => {
                return accumulator + Number(formatEther(current));
            }, 0);
            let arr = []
            arr = [
                {
                    label: 'Energy',
                    num: formatEther(playerDetail.data[0][0]),
                    cellNum: formatEther(playerDetail.data[1][0]),
                },
                { label: 'BitCell NFT', num: 0, cellNum: '' },
                {
                    label: 'Airdrop',
                    num: formatEther(playerDetail.data[0][1]),
                    cellNum: formatEther(playerDetail.data[1][1]),
                },
                {
                    label: 'Life Points',
                    num: formatEther(playerDetail.data[0][2]),
                    cellNum: formatEther(playerDetail.data[1][2]),
                },
                { label: 'Total Received', num: '', cellNum: 0 },
            ];
            setTotalInfo(arr)
        }
    }, [playerDetail.isSuccess]);
    // 获取玩家解锁信息（适用于 Cliam 之前）
    const playerStepsInfo: any = usePlayerStepsInfo(address, [0, 1, 2, 3, 4, 5, 6]);
    useEffect(() => {
        if (playerStepsInfo.isSuccess) {
            const totalArr = sumElements(playerStepsInfo.data[0])
            let arr = []
            arr = playerStepsInfo.data[0].map((item:any, index:number) => {
                const currentTotal = sumElements(playerStepsInfo.data[0].slice(0, index + 1))
                const currentUnlock = Number(formatEther(item[0])) + Number(formatEther(item[1])) + Number(formatEther(item[2]))
                const lastTotal = getTotal(totalArr) - getTotal(currentTotal)
                return {
                    status: 0,
                    data: formatTimestampToDate(Number(playerStepsInfo.data[1][index]) * 1000),
                    list: [
                        {label: 'Energy', unlockRate: `${Math.abs(Number(formatEther(item[0])) / totalArr[0] * 100).toFixed(0)}%`, currentUnlock: Number(formatEther(item[0])), lastCellNum: totalArr[0] - currentTotal[0]},
                        {label: 'BitCell NFT', unlockRate: 10, currentUnlock: '33', lastCellNum: '33'},
                        {label: 'AirDrop', unlockRate: `${Math.abs(Number(formatEther(item[1])) / totalArr[1] * 100).toFixed(0)}%`, currentUnlock: Number(formatEther(item[1])), lastCellNum: totalArr[1] - currentTotal[1]},
                        {label: 'Life Points', unlockRate: `${Math.abs(Number(formatEther(item[2])) / totalArr[2] * 100).toFixed(0)}%`, currentUnlock: Number(formatEther(item[2])), lastCellNum: totalArr[2] - currentTotal[2]},
                        {label: 'Total Received', unlockRate: '', currentUnlock: currentUnlock, lastCellNum: lastTotal},
                    ]
                }
            })
            setStepInfo(arr)
        }
    }, [playerStepsInfo.isSuccess]);

    const handleTotalBitcell = (arr:any) => {
        const total = arr.reduce((accumulator: any, current: any) => {
            return accumulator + current.claimList.reduce((innerAcc:any, obj:any) => {
                return innerAcc + Number(obj.claimAmount)
            }, 0)
        }, 0)
        return total
    }

    const handleCurrentBitcell = (arr:any, index:number) => {
        const total = arr.reduce((accumulator: any, current: any) => {
            return accumulator + Number(current.claimList[index].claimAmount)
        }, 0)
        return total
    }

    const handleLastBitcell = (arr:any, index:number) => {
        const total = arr.reduce((accumulator: any, current: any) => {
            return accumulator + current.claimList.reduce((innerAcc:any, obj:any) => {
                return innerAcc + Number(obj.claimAmount)
            }, 0)
        }, 0)
        const currentTotal = arr.reduce((accumulator: any, current: any) => {
            return accumulator + current.claimList.slice(0, index + 1).reduce((innerAcc:any, obj:any) => {
                return innerAcc + Number(obj.claimAmount)
            }, 0)
        }, 0)
        return total - currentTotal
    }

    const handleData = (stepInfo:any, bitCellInfo:any, totalInfo:any) => {
        const bitCellNum = bitCellInfo.length
        stepInfo.forEach((item:any, index:number) => {
            item.list.forEach((obj:any, i:number) => {
                const currentBitcell = handleCurrentBitcell(bitCellInfo, index)
                const lastBitcell = handleLastBitcell(bitCellInfo, index)
                if (obj['label'] === 'BitCell NFT') {
                    obj['unlockRate'] = item.list[i + 1].unlockRate
                    obj.currentUnlock = currentBitcell
                    obj.lastCellNum = lastBitcell
                }
                if (obj['label'] === 'Total Received') {
                    obj.currentUnlock = currentBitcell + obj.currentUnlock
                    obj.lastCellNum = lastBitcell + obj.lastCellNum
                }
            })
        })
        totalInfo.forEach((item:any, index:number) => {
            if (item['label'] === 'BitCell NFT') {
                item['num'] = bitCellNum
                item['cellNum'] = handleTotalBitcell(bitCellInfo)
            }
        })
        totalInfo[totalInfo.length - 1]['cellNum'] = totalInfo.reduce((accumulator:any, current: any) => {
            return accumulator + Number(current.cellNum)
        }, 0)
        const info = {
            status: 2,
            list: totalInfo,
        }
        stepInfo.unshift(info)
        setData(stepInfo)
    }

    useEffect(() => {
        // if (stepInfo.length > 0 && bitCellInfo.length > 0 && totalInfo.length > 0) {
        //     handleData(stepInfo, bitCellInfo, totalInfo)
        // }
        if (Array.isArray(stepInfo) && Array.isArray(bitCellInfo) && Array.isArray(totalInfo)) {
            handleData(stepInfo, bitCellInfo, totalInfo)
        }
    }, [stepInfo, bitCellInfo, totalInfo]); // 依赖 userData 和 settingsData
    return (
        <div className={'show-flex-box-r show-flex-center ff-SnasmBook air-drop-detail-wrap'}>
            <div className={'bg'}>
                <div className={'left-arrow'} onClick={closeVisible}></div>
                <div onClick={closeVisible} className={'cursor-pointer close-icon'}></div>
                <div className={'color-white ff-Snasm title'}>Token Airdrop Details</div>
                {
                    !isLoading &&
                    <div className={'content'}>
                        {
                            data.length > 0 ?
                                <div>
                                    {
                                        data.map((item: any, index: number) => (
                                            <div key={index}>
                                                {
                                                    item.status === 2 &&
                                                    <div className={'status-2'}>
                                                        <div className={'show-flex-box-r table-title'}>
                                                            {
                                                                unlockTabList.map((title: any, i: number) => (
                                                                    <div key={i}>{title}</div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className={'show-flex-box-c table-content'}>
                                                            {
                                                                item.list.map((info: any, i: number) => (
                                                                    <div key={i}
                                                                         className={`single-row ${info.label === 'Total Received' ? 'last-row' : ''}`}>
                                                                        <div className={'first'}>{info.label}</div>
                                                                        <div>
                                                                            <div className={'phone-table-label'}>My
                                                                                Holding
                                                                            </div>
                                                                            <div
                                                                                className={'num'}>{handlePermillage(truncateNumber(info.num, 8))}</div>
                                                                        </div>
                                                                        <div>
                                                                            <div className={'phone-table-label'}>
                                                                                Current Unclock
                                                                            </div>
                                                                            <div className={'cellNum'}>
                                                                                <div>{handlePermillage(truncateNumber(info.cellNum, 8))}</div>
                                                                                <div className={'pc-unit'}> CELA</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={'phone-unit'}> CELA</div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item.status === 0 &&
                                                    <div className={'status-0'}>
                                                        <div className={'color-white time'}>
                                                            <div className={'color-white label'}>{item.data} Unlock
                                                            </div>
                                                        </div>
                                                        <div className={'show-flex-box-r table-title'}>
                                                            {
                                                                notUnlockTabList.map((title: any, i: number) => (
                                                                    <div key={i}>{title}</div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className={'show-flex-box-c table-content'}>
                                                            {
                                                                item.list.map((info: any, i: number) => (
                                                                    <div key={i}
                                                                         className={`single-row ${info.label === 'Total Received' ? 'last-row' : ''}`}>
                                                                        <div className={'first'}>{info.label}</div>
                                                                        <div
                                                                            className={'unlock-rate'}>{info.unlockRate}</div>
                                                                        <div
                                                                            className={'phone-table-label unlock-rate-phone'}>Unlock
                                                                            Percentage：{info.unlockRate}</div>
                                                                        <div className={'pc-current-unlock'}>
                                                                            <div className={'phone-table-label'}>
                                                                                Upcoming Unlocks
                                                                            </div>
                                                                            <div
                                                                                className={'current-unlock'}>{handlePermillage(truncateNumber(info.currentUnlock, 8))}</div>
                                                                        </div>
                                                                        <div className={'pc-last-cell-num'}>
                                                                            <div
                                                                                className={'phone-table-label'}>Remaining
                                                                                Unlocks
                                                                            </div>
                                                                            <div
                                                                                className={'last-cell-num'}>{handlePermillage(truncateNumber(info.lastCellNum, 8))}
                                                                                <div>CELA</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                <div className={'w100 h100'}>
                                    <NoData></NoData>
                                </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}