import { useReadContract, useWriteContract } from 'wagmi';
import { contractAbiMap, ContractAbiTypeEnum } from "src/enums/contractAbiEnum.ts";
import { networkList } from "src/hook/network.ts";
import { networkStatus } from "src/hook/networkStatus.ts";
const abi = JSON.parse(contractAbiMap.get(ContractAbiTypeEnum.CELL_TOKEN) as string)
const lifeContract: any = networkList[networkStatus].stakingContract
const contractAddress: any = networkList[networkStatus].tokenContract

export const useTokenApprove = (address: string) => {
    const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'allowance',
        args: [address, lifeContract],
    });

    return { data, isError, isLoading, isSuccess, refetch };
};
export const useTokenBalance = (address: string) => {
    const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'balanceOf',
        args: [address],
    });

    return { data, isError, isLoading, isSuccess, refetch };
};