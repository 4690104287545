// Message.tsx
import React, { useEffect, useState } from 'react';
import './Message.scss'; // 创建一个对应的 CSS 文件以便我们自定义样式
import loadingImg from 'img/layout/loading.png'

interface MessageProps {
    message: string;
    onClose: () => void;
    duration: number | null
}

const Message: React.FC<MessageProps> = ({ message, onClose, duration }) => {
    const [rate, setRate] = useState(1);
    useEffect(() => {
        if (duration === 0) {
            const totalDuration = 5000; // 总时间5秒
            const totalSteps = 90; // 总步数90
            const intervalDuration = totalDuration / totalSteps; // 每一步的时间间隔

            let currentStep = 1;

            const intervalId = setInterval(() => {
                if (currentStep >= totalSteps) {
                    clearInterval(intervalId);
                } else {
                    setRate(currentStep + 1);
                    currentStep++;
                }
            }, intervalDuration);

            // 清理函数以防止内存泄漏
            return () => clearInterval(intervalId);
        }
    }, []);

    return (
        <div className={`message-container`} onClick={onClose}>
            <div className={`message-content ${duration === 0 ? 'loading-wrap' : '' }`}>
                <div className={'message'}>{message}</div>
                {
                    duration === 0 ?
                        // <div className={'progress-container'}>
                        //     <div style={{width: `${rate}%`}} className={'rate'}></div>
                        // </div>
                        <div className={'loading'}></div>
                        : null
                }
            </div>
        </div>
    );
};

export default Message;
