import { defaultWagmiConfig } from '@web3modal/wagmi'
import { bsc } from "viem/chains";
import { networkStatus } from "src/hook/networkStatus.ts";

const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'https://web3modal.com', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}
const projectId = '8b3fa7c95ab1c2257bfe4a9cd850ca57'
const bnbTestnet = {
    id: 97,
    name: 'BNB Smart Chain Testnet',
    nativeCurrency: { name: 'tBNB', symbol: 'tBNB', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://bsc-testnet-rpc.publicnode.com'],
        },
    },
    blockExplorers: {
        default: {
            name: 'BscScan Testnet',
            url: 'https://testnet.bscscan.com',
        },
    }
}
const bnbMainNet = {
    id: 56,
    name: 'BNB Smart Chain',
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://tame-morning-waterfall.bsc.quiknode.pro/7b8a58be397bd265138aa7bcb2ae10e4d7701f9e/'],
        },
    },
    blockExplorers: {
        default: {
            name: 'BscScan',
            url: 'https://bscscan.com',
        },
    }
}
const chains = [networkStatus ? bnbMainNet : bnbTestnet] as never
export const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
})