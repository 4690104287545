import request from '../utils/request'

const URL = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    GET_MESSAGE: 'getMessage',
    GET_CELL: 'cells',
    GET_TIME: '/btc/time',
    GET_BITCELL_LIST: '/bitcellClaim/queryBitcellClaimList',
}
export default {
    login(formData: any) {
        return request.post(URL.LOGIN, formData)
    },
    logout() {
        return request.post(URL.LOGOUT)
    },
    getMessage(address: any) {
        return request.get(URL.GET_MESSAGE, {
            params: {
                ethAddress: address
            }
        })
    },
    getCellsList(qty?: number) {
        return request.get(URL.GET_CELL, {
            params: {
                pageNum: 1,
                pageSize: 600,
                qty: qty
            }
        })
    },
    getTime() {
        return request.get(URL.GET_TIME)
    },
    getBitcellList(address: any) {
        return request.get(URL.GET_BITCELL_LIST, {
            params: {
                ethAddress: address
            }
        })
    },
}