
      // Import the functions you need from the SDKs you need
      import { initializeApp } from "https://www.gstatic.com/firebasejs/10.5.0/firebase-app.js";
      import { getAnalytics } from "https://www.gstatic.com/firebasejs/10.5.0/firebase-analytics.js";
      // TODO: Add SDKs for Firebase products that you want to use
      // https://firebase.google.com/docs/web/setup#available-libraries

      // Your web app's Firebase configuration
      // For Firebase JS SDK v7.20.0 and later, measurementId is optional
      const firebaseConfig = {
        apiKey: "AIzaSyDDWq7XCMvwrsSYh3P72tRek1rjzwvuhIo",
        authDomain: "cellulalife-899e6.firebaseapp.com",
        projectId: "cellulalife-899e6",
        storageBucket: "cellulalife-899e6.appspot.com",
        messagingSenderId: "429645570288",
        appId: "1:429645570288:web:1d9c3b5bfb3e63bf686235",
        measurementId: "G-ZMZ27Z5NN1"
      };

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
    