import request from '../utils/request'

const URL = {
    SAVE_HASH: 'btc/applyUser',
    GET_ACTIVITY_STATUS: 'btc/queryCount',
    GET_MINT_STATUS: 'btc/queryUserMint',
    GET_CELL_ASSETS: 'btc/queryMyCellRgbppAssert',
    GET_TIME: 'btc/time',
    GET_MESSAGE: 'btc/getMessage',
    QUERY_BITCELL: 'btc/queryBitcellEvmAddr',
    QUERY_MY_BITCELL: 'btc/queryMyBitcellAssert',
    BIND_EVM_ADDRESS: 'btc/rbgAssertBindingEvmAddress',
    CLAIM_TOKEN: 'bitcellClaim/claimToken',
    GET_CLAIM_LIST: 'bitcellClaim/queryBitcellClaimList'
}

export default {
    saveHash(address: string, type: number, txId: string) {
        return request.get(URL.SAVE_HASH, {
            params: {
                btcAddress: address,
                txId: txId,
                type: type
            }
        })
    },
    getActivityStatus(type: number) {
        return request.get(URL.GET_ACTIVITY_STATUS, {
            params: {
                type: type
            }
        })
    },
    getUserMint(address: string) {
        return request.get(URL.GET_MINT_STATUS, {
            params: {
                btcAddress: address
            }
        })
    },
    getCellAssets(address: string) {
        return request.get(URL.GET_CELL_ASSETS, {
            params: {
                btcAddress: address
            }
        })
    },
    getTime() {
        return request.get(URL.GET_TIME)
    },
    getMessage(btcPubkey: string) {
        return request.get(URL.GET_MESSAGE, {
            params: {
                btcPubkey: btcPubkey
            }
        })
    },
    queryMyBitCellAssets(btcAddress: string) {
        return request.get(URL.QUERY_MY_BITCELL, {
            params: {
                btcAddress: btcAddress
            }
        })
    },
    queryBitCellEVMAddress(bitcellId: string) {
        return request.get(URL.QUERY_BITCELL, {
            params: {
                bitcellId: bitcellId
            }
        })
    },
    bindEVMAddress(formData: any) {
        return request.post(URL.BIND_EVM_ADDRESS, formData)
    },
    getClaimList(address: any) {
        return request.get(URL.GET_CLAIM_LIST, {
            params: {
                ethAddress: address
            }
        })
    },
    claimToken(formData: any) {
        return request.post(URL.CLAIM_TOKEN, formData)
    }
}