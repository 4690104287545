import "./Airdrop.scss"
import { useEffect, useRef, useState } from "react";
import { Empty, Modal } from "antd";
import { CheckVisible } from "src/view/bitcell/CheckVisible.tsx";
import { BindVisible } from "src/view/bitcell/BindVisible.tsx";
import { RuleVisible } from "src/view/bitcell/RuleVisible.tsx";
import { ConnectVisible } from "src/view/bitcell/ConnectVisible.tsx";
import { getAccounts } from "@joyid/bitcoin";
import { useGlobalState } from "src/hook/useGlobalState.ts";
import { initOKXWallet } from "src/hook/OKXInit.ts";
import { getStr, isMobileOrTablet } from "src/utils/utils.ts";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { Btc, User } from "src/api";
import { useNavigate } from "react-router-dom";
import { useDisconnect } from "wagmi";
import { useMessage } from "src/view/components/MessageContext.tsx";
interface BitCell {
    bitcellId: string
    boundEvmAddr: string | null
    isValid: boolean
}
export const Airdrop = () => {
    const [emptyVisible, setEmptyVisible] = useState(false)
    const [checkVisible, setCheckVisible] = useState(false)
    const [bindVisible, setBindVisible] = useState(false)
    const [ruleVisible, setRuleVisible] = useState(false)
    const [connectVisible, setConnectVisible] = useState(false)
    const [bitcellList, setBitCellList] = useState([] as BitCell[])
    const cells = [
        "https://img.cellula.life/rankback/kzzq8dozzw6ncr32d7ut.png",
        "https://img.cellula.life/rankback/8tqcq2qchuozenri4vaw.png",
        "https://img.cellula.life/rankback/fvri560x4sxyipgko81v.png",
        "https://img.cellula.life/rankback/5k50w1w7u1bqlth54s5e.png",
        "https://img.cellula.life/rankback/92klyse98zd7j7kb9al1.png"]
    const [cellIndex, setCellIndex] = useState(0)
    const [currentBitCell, setCurrentBitCell] = useState({} as BitCell)
    const navigate = useNavigate()
    const { btcAddress, setBtcAddress, address, setIsLoading } = useGlobalState()
    const { showMessage } = useMessage()
    const { open } = useWeb3Modal()
    const [innerWidth, setInnerWidth] = useState(0)

    // connect
    useEffect(() => {
        disconnect()
        cells.forEach((src) => {
            const img = new Image();
            img.src = src;
        });
        const btcWallet = localStorage.getItem('btcWallet')
        if (btcWallet) {
            initWallet(btcWallet)
        }
        // 定义一个生成随机数的函数
        const generateRandomNumber = () => Math.floor(Math.random() * 4);

        // 每5秒更新cellIndex
        const intervalId = setInterval(() => {
            setCellIndex(generateRandomNumber());
        }, 3000);
        const handleResize = () => {
            setInnerWidth(window.innerWidth);
        };
        setInnerWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            clearInterval(intervalId);
        };
    }, []);
    const checkOkxWallet = async () => {
        if (isMobileOrTablet()) {
            if (typeof (window as any).okxwallet !== 'undefined') {
                try {
                    const accounts = await (window as any).bitcoin.requestAccounts();
                    setBtcAddress(accounts[0])
                } catch (e) {
                    console.log('connect failed', e);
                }
            } else {
                initOKXWallet()
            }
        } else {
            const result = await connectOkx()
            if (result) {
                setBtcAddress(result[0])
                localStorage.setItem('btcWallet', 'okx')
            }
        }
    };
    const connectOkx = async () => {
        try {
            if (typeof (window as any).okxwallet !== 'undefined') {
                const res = await (window as any).okxwallet.bitcoin.requestAccounts();
                return res
            } else {
                console.log('OKX Wallet is not installed.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const initWallet = async (type: string) => {
        if (type === 'okx') {
            await checkOkxWallet()
        } else {
            try {
                const res =  getAccounts();
                setBtcAddress(res[0])
            } catch (e) {
                console.log(e, 'error');
            }
        }
    }
    const btcDisconnect = () => {
        setBtcAddress('')
        setConnectVisible(true)
        localStorage.removeItem('btcWallet')
    }
    // get bitcell list
    const queryBitCellAssets = () => {
        setIsLoading(true)
        Btc.queryMyBitCellAssets(btcAddress).then((res: any) => {
            if (res.code === 200) {
                setBitCellList(res.data)
            } else {
                setBitCellList([])
            }
            setIsLoading(false)
        })
    }
    const chooseBitCell = (bitcell: BitCell) => {
        if (bitcell.boundEvmAddr) {
            setBtcAddress('')
            open()
            localStorage.removeItem('btcWallet')
        } else {
            setCurrentBitCell(bitcell)
            setBindVisible(true)
        }
    }
    useEffect(() => {
        if (btcAddress) {
            queryBitCellAssets()
        } else {
            setBitCellList([])
        }
    }, [btcAddress]);
    const refreshData = () => {
        setBindVisible(false)
        queryBitCellAssets()
    }
    const getClaimList = () => {
        Btc.getClaimList(address).then((res: any) => {
            if (!res.data.length) {
                setEmptyVisible(true)
            } else {
                navigate('/bitcell-claim')
            }
        })
    }
    const isFirst = useRef(false)
    useEffect(() => {
        if (address && isFirst.current) {
            getClaimList()
        }
        isFirst.current = true
    }, [address]);
    const { disconnect } = useDisconnect()

    const connectBtc = () => {
        disconnect()
        setEmptyVisible(false)
        setConnectVisible(true)
    }
    const checkCount = () => {
        if (address) {
            getClaimList()
        } else {
            open()
        }
    }
    return (
        <div className={'btc-airdrop-page'}>
            <div className={'bitcell-container'}>
                <div className={'left-container'}>
                    <div className={'animate-bg'}>
                        <div className={'logo logo-1'}>
                            <div className={'label'}>Airdrops</div>
                        </div>
                        <div className={'logo logo-2'}>
                        </div>
                        <div className={'logo logo-3'}>
                            <div className={'label'}>Incentives</div>
                        </div>
                        <div className={'logo logo-4'}>
                        </div>
                        <div className={'logo logo-5'}>
                            <div className={'label'}>Ecosystem</div>
                        </div>
                        <div className={'logo logo-6'}>
                        </div>
                        {/*<div className={'tips-bg-1'}>*/}
                        {/*    CELA Airdop*/}
                        {/*</div>*/}
                        {/*<div className={'tips-bg-2'}>*/}
                        {/*    Incentives from<br/>*/}
                        {/*    Ecosystem Partners*/}
                        {/*</div>*/}
                        <div className={'cell-container'}>
                            <div className={'cell-box'}>
                                <div className={'cell'} style={{ backgroundImage: `url('${cells[cellIndex]}')` }}></div>
                            </div>
                            <div className={'description'}>BitCell</div>
                        </div>
                    </div>
                </div>
                <div className={'right-container'}>
                    <div className={'header-btn'}>
                        <div onClick={() => setCheckVisible(true)} className={'modal-btn check'}>Check BitCell</div>
                        <div onClick={() => window.open('https://medium.com/@cellulalifegame/68a480cb2d32')} className={'modal-btn bind'}>Check Details</div>
                    </div>
                    <div className={'bold-title'}>
                        BitCell NFT Holders:<br/>
                        Claim Your&nbsp;{innerWidth <= 750 ? <br/> : null }<span className={'second-span'}>CELA&nbsp;Airdrop</span>
                    </div>
                    {
                        !btcAddress ?
                            <div className={'connect-container'}>
                                <div className={'button-item'}>
                                    <div onClick={() => setConnectVisible(true)} className={'connect-button'}>
                                        Bind BitCell
                                    </div>
                                    <div className={'description'}>
                                        Connect Your BTC Wallet to Bind<br/>
                                        BitCell NFT
                                    </div>
                                </div>
                                <div className={'button-item'}>
                                    <div onClick={checkCount} className={'connect-button'}>
                                        Claim Airdrop
                                    </div>
                                    <div className={'description'}>
                                        Connect Your EVM Wallet (0x....) to Claim<br/>
                                        Your Airdrop
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={'holding-container'}>
                                <div className={'header-block'}>
                                    <div className={'title'}>
                                        Holding BitCell
                                    </div>
                                    <div onClick={btcDisconnect} className={'address'}>
                                        {getStr(btcAddress,6,6)}
                                        <div className={'switch-icon'}></div>
                                    </div>
                                </div>
                                <div className={'data-container'}>
                                    {
                                        bitcellList.length ?
                                        bitcellList.map((item, index) => (
                                            <div key={index} className={'data-item'}>
                                                <div className={'label'}>
                                                    <div className={'label-icon'}></div>
                                                    ID:{item.bitcellId ? getStr(item.bitcellId,4,4) : '---'}
                                                </div>
                                                <div className={'evm-address'}>
                                                    {item.boundEvmAddr ? getStr(item.boundEvmAddr,4,4) : '---'}
                                                </div>
                                                <div className={'button-container'}>
                                                    <div onClick={() => chooseBitCell(item)} className={`btn-bind ${item.boundEvmAddr ? 'btn-bind-active' : ''}`}>
                                                        {item.boundEvmAddr ? 'View Detail' : 'Bind EVM Address'}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                            : <Empty></Empty>
                                    }
                                </div>
                            </div>
                    }
                </div>
            </div>
            <Modal
                open={emptyVisible}
                footer={null}
                getContainer={false}
                closable={false}
            >
                <div className={'empty-visible'}>
                    <div onClick={() => setEmptyVisible(false)} className={'close-icon'}></div>
                    <div className={'empty-icon'}></div>
                    <div className={'description'}>
                        Sorry, your airdrop information was not found.
                        You need to connect the BTC account to complete the binding and view it.
                    </div>
                    <div className={'button-container'}>
                        {/*<div onClick={() => setEmptyVisible(false)} className={'btn back'}>Back</div>*/}
                        <div onClick={connectBtc} className={'btn connect'}>Connect BTC</div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={checkVisible}
                footer={null}
                getContainer={false}
                closable={false}
            >
                <CheckVisible closeVisible={() => setCheckVisible(false)}></CheckVisible>
            </Modal>
            <Modal
                open={bindVisible}
                footer={null}
                getContainer={false}
                closable={false}
            >
                <BindVisible bindSuccess={refreshData} bitCell={currentBitCell} closeVisible={() => setBindVisible(false)}></BindVisible>
            </Modal>
            {/*<Modal*/}
            {/*    open={ruleVisible}*/}
            {/*    footer={null}*/}
            {/*    getContainer={false}*/}
            {/*    closable={false}*/}
            {/*>*/}
            {/*    <RuleVisible closeVisible={() => setRuleVisible(false)}></RuleVisible>*/}
            {/*</Modal>*/}
            <Modal
                open={connectVisible}
                footer={null}
                getContainer={false}
                closable={false}
            >
                <ConnectVisible closeVisible={() => setConnectVisible(false)}></ConnectVisible>
            </Modal>
        </div>
    )
}