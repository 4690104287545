import { Outlet } from "react-router-dom";
import { Header } from "./Header.tsx";
import {Loading} from "src/view/components/loading.tsx";
import { useGlobalState } from "src/hook/useGlobalState.ts";

export const Layout = function () {
    const {isLoading} = useGlobalState()
    return (
        <div className={"layout-container"}>
            {
                isLoading && <Loading></Loading>
            }
            <header>
                <Header></Header>
            </header>
            <main>
                <Outlet></Outlet>
            </main>
        </div>
    )
}