import { Input } from "antd";
import React, { useState } from "react";
import { useGlobalState } from "src/hook/useGlobalState.ts";
import { Btc } from "src/api";
import { getStr } from "src/utils/utils.ts";

interface CheckVisibleProps {
    closeVisible: ()=> void
}
export const CheckVisible:React.FC<CheckVisibleProps> = ({ closeVisible }) => {
    const [value, setValue] = useState('')
    const [bindAddress, setBindAddress] = useState(null as string | null)
    const { address } = useGlobalState()
    const [errorMsg, setErrorMsg] = useState('')
    const isEVMAddress = (address: string): boolean => {
        // 正则表达式：以0x开头，只包含字母和数字（不包括中文、空格和符号）
        const evmPattern = /^0x[a-zA-Z0-9]{1,}$/;
        // 测试地址是否符合正则表达式
        return evmPattern.test(address);
    };

    const changeValue = (value: any) => {
        setBindAddress(null)
        setValue(value.target.value)
    }
    const checkAddress = () => {
        if (isEVMAddress(value)) {
            Btc.queryBitCellEVMAddress(value).then((res: any) => {
                if (res.code === 200) {
                    setBindAddress(res.data)
                } else {
                    setErrorMsg(res.message)
                    setBindAddress('')
                }
            })
        }
    }
    const closeView = () => {
        setValue('')
        closeVisible()
    }
    return (
        <div className={'check-visible'}>
            <div onClick={closeView} className={'close-icon'}></div>
            <div className={'title-bold'}>
                Check BitCell
            </div>
            <div className={'input-container'}>
                <div className={'title'}>
                    Please enter the BitCell ID: 0x5c4a699.....
                </div>
                <div className={'input'}>
                    <Input value={value} onChange={(e) => changeValue(e)}  placeholder={''}></Input>
                </div>
                {
                    value !== ''
                        ?
                        <div className={`error`}>
                            {bindAddress === null ? (!isEVMAddress(value) ? 'Invalid Network Address' : '') : (bindAddress ? `Bound EVM Address: ${getStr(bindAddress,4,4)}` : errorMsg)}
                        </div>
                        : null
                }
                <div className={'button-container'}>
                    <div onClick={checkAddress} className={`check-btn ${value.length ? 'yellow' : 'btn-disabled'}`}>Check</div>
                </div>
            </div>
        </div>
    )
}