import './App.scss'
import { BrowserRouter } from "react-router-dom";
import GetRouters from "./router"
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { WagmiProvider } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { config } from "./hook/config.ts";
import { GlobalStateProvider } from "./hook/GlobalStateProvider";
import { ConfigProvider, Slider } from "antd";
import { MessageProvider } from "src/view/components/MessageContext.tsx";
import { CopyProvider } from "src/hook/CopyContext.tsx";
import { useEffect } from "react";

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '8b3fa7c95ab1c2257bfe4a9cd850ca57'
// 3. Create modal
createWeb3Modal({
    wagmiConfig: config,
    projectId,
    // enableAnalytics: true, // Optional - defaults to your Cloud configuration
    // enableOnramp: false, // Optional - false as default
    // allWallets: 'SHOW',
    featuredWalletIds: [
        '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
        '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
        '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
        '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
        '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
        '7674bb4e353bf52886768a3ddc2a4562ce2f4191c80831291218ebd90f5f5e26',
        '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',
        '24fd8104e07aa1802aef51426222f23868013b83655fb6686c8478744dd2fda1'
    ]
})
function App() {
    useEffect(() => {
        const setVH = () => {
            const vh = window.innerHeight;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        // 初始加载时设置变量
        setVH();

        // 窗口大小变化时更新变量
        window.addEventListener('resize', setVH);

        // 组件卸载时移除事件监听
        return () => {
            window.removeEventListener('resize', setVH);
        };
    }, []);
  return (
    <>
        <GlobalStateProvider>
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Slider: {
                                    handleColor: '#3780C1',
                                    trackBg: '#EA971A',
                                    handleLineWidth: 4,
                                    railSize: 12,
                                    railBg: '#010819',
                                },
                                Input: {
                                    addonBg: '#011326',
                                    hoverBg: '#011326',
                                    activeBg: '#011326',
                                    colorBgContainer: '#011326',
                                    colorText: '#FFF',
                                    activeBorderColor: 'rgba(6, 111, 223, 0.30)',
                                    colorBorder: 'rgba(6, 111, 223, 0.30)',
                                    colorTextPlaceholder: 'rgba(255, 255, 255, 0.40)',
                                    paddingBlock: 10
                                }
                            }
                        }}
                    >
                        <MessageProvider>
                            <CopyProvider>
                                <BrowserRouter>
                                    <GetRouters />
                                </BrowserRouter>
                            </CopyProvider>
                        </MessageProvider>
                    </ConfigProvider>
                </QueryClientProvider>
            </WagmiProvider>
        </GlobalStateProvider>
    </>
  )
}

export default App
