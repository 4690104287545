export function getStr(string: any, forward: number, later: number) {
    const strLength = string?.length
    return string ? string.substring(0, forward) + '****' + string.substring(strLength - later, strLength) : ''
}

export function rearrangeMatrix (input: string) {
    // 将输入字符串分成 9 组，每组 9 位
    const groups = Array.from({ length: 9 }, (_, i) => input.slice(i * 9, (i + 1) * 9));

    // 初始化 9x9 的矩阵
    const matrix = Array.from({ length: 9 }, () => Array(9).fill('0'));

    // 按行列重新排列
    groups.forEach((group, index) => {
        const startRow = Math.floor(index / 3) * 3;
        const startCol = (index % 3) * 3;
        group.split('').forEach((char, idx) => {
            const row = startRow + Math.floor(idx / 3);
            const col = startCol + (idx % 3);
            matrix[row][col] = char;
        });
    });

    // 将矩阵转换回字符串
    return matrix.flat().join('');
}

export const getLitGrids = ((matrix: any, pattern: any) => {
    const x = Math.floor(matrix / 3) * 3;
    const y = (matrix % 3) * 3;
    const litGrids = [];
    for (let i = 0; i < 9; i++) {
        const digit = pattern.charAt(i);
        if (digit === '1') {
            const row = Math.floor(i / 3) + x;
            const col = (i % 3) + y;
            litGrids.push([row, col]);
        }
    }
    return litGrids;
})

export function splitEvoHistories(evoHistories: any[]) {
    // 使用map函数分别提取qtyCount和step值
    const qtyCountArr = evoHistories.map(e => e.qtyCount);
    const stepArr = evoHistories.map(e => e.step);

    // 返回一个对象，包含两个数组
    return { qtyCountArr, stepArr };
}
export function findOnesPositions(binaryString: string): number[][] {
    // 确保输入的字符串长度正好为81（9x9）
    if (binaryString.length !== 81) {
        throw new Error("the binary string must have exactly 81 characters.");
    }

    // 将字符串转换为9x9的二维数组
    const grid: string[][] = [];
    for (let i = 0; i < 81; i += 9) {
        grid.push(binaryString.slice(i, i + 9).split(''));
    }

    // 用于存储值为 '1' 的元素的位置
    const positions: number[][] = [];

    // 遍历二维数组，找到所有 '1' 的位置
    for (let i = 0; i < 9; i++) { // 行
        for (let j = 0; j < 9; j++) { // 列
            if (grid[i][j] === '1') {
                positions.push([i, j]);
            }
        }
    }

    return positions;
}
export function truncateNumber(num: any, precision: number = 6, isString: boolean = false) {
    precision = 6
    if (num === null || num === undefined || isNaN(num) || parseFloat(num) < 0.000001) return 0;

    // Convert num to a string
    let numStr = num.toString();

    // If the number is in scientific notation, let's ensure we avoid scientific notation in the final result
    if (isString) {
        // Check if the number is in scientific notation
        if (numStr.includes('e') || numStr.includes('E')) {
            // Use toFixed or toLocaleString to convert to a readable string without scientific notation
            // using `precision || 6` to handle precision properly
            numStr = parseFloat(numStr).toFixed(precision);
        }
    }

    // Find the position of the decimal point
    const dotIndex = numStr.indexOf('.');

    if (dotIndex !== -1) { // Check if there is a decimal point
        // Get the number of digits after the decimal point
        const lengthAfterDot = numStr.length - dotIndex - 1;
        // Ensure truncation respects the precision
        const truncatedStr = numStr.substring(0, dotIndex + (precision || 6) + 1);

        // If isString is true, return as string, ensuring no scientific notation
        if (isString) {
            return truncatedStr;
        }

        return parseFloat(truncatedStr);
    }

    // If no decimal point, return the number as is (no truncation)
    return num;
}

export function totalSum(count: number, price: number): number {
    let total = 0;
    for (let i = 1; i <= count; i++) {
        total += price * (1 + 0.05 * i);
    }
    return truncateNumber(total, 6);
}
export function canContinuePaging(currentPage: number, itemsPerPage: number, totalItems: number) {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    return currentPage < totalPages;
}

export function formatTimestampToDDHHMM(timestamp: any) {
    // 计算距离现在的时间差（毫秒）
    const now = Date.now();
    const diff = timestamp - now;

    // 确保时间戳是未来的时间
    if (diff <= 0) {
        return '00:00:00';
    }

    // 将时间差（毫秒）转换为天、小时和分钟
    const days = Math.floor(diff / (24 * 60 * 60 * 1000));
    const hours = Math.floor((diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));

    // 格式化为两位数
    const dd = String(days).padStart(2, '0');
    const hh = String(hours).padStart(2, '0');
    const mm = String(minutes).padStart(2, '0');

    // 返回格式化的时间字符串
    return `${dd}D - ${hh}H - ${mm}M`;
}
export function formatNumberForEnglish(input: number | string | null): string | null {
    if (input === null) {
        return null;
    }

    const value = typeof input === 'string' ? parseFloat(input) : input;
    if (isNaN(value)) {
        return null;
    }

    if (value >= 1e9) {
        return truncateNumber(value / 1e9, 3) + 'B';
    } else if (value >= 1e6) {
        return truncateNumber(value / 1e6, 3) + 'M';
    } else if (value >= 1e3) {
        return truncateNumber(value / 1e3, 3) + 'K';
    } else {
        return value.toString();
    }
}
export function formatNumberWithCommas(x: number | string | null | any): string {
    if (x === null) return ''
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export function formatDate(timestamp: number) {
    // 创建一个新的日期对象
    const date = new Date(timestamp);

    const year = date.getFullYear().toString();

    // 获取月份，然后加一因为 getMonth() 返回的月份是从 0 开始的
    // 使用 padStart() 来确保数字是两位数的
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    // 获取日期，使用 padStart() 来确保是两位数的
    const day = date.getDate().toString().padStart(2, '0');

    // 获取小时，使用 padStart() 来确保是两位数的
    const hours = date.getHours().toString().padStart(2, '0');

    // 获取分钟，使用 padStart() 来确保是两位数的
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // 将各个时间部分组合成一个字符串
    return `${year}-${month}-${day}`;
}
export function checkTimeStatus(startTime: number, endTime: number): string {
    if (!startTime || !endTime) {
        return 'no-start'
    }
    const currentTime = Date.now();

    if (currentTime < startTime) {
        return "no-start";
    } else if (currentTime >= startTime && currentTime <= endTime) {
        return "start";
    } else {
        return "";
    }
}
export function getRemainingDays(endTime: number): number {
    const currentTime = Date.now();
    const timeDifference = endTime - currentTime;

    const daysRemaining = timeDifference / (1000 * 60 * 60 * 24);

    if (daysRemaining < 1) {
        return 0;
    } else if (daysRemaining < 2) {
        return 1;
    } else {
        return Math.floor(daysRemaining);
    }
}

export function calculateRemainingTimeDay(startTime: number): string {
    const currentTime = Date.now();
    const timeDifference = startTime - currentTime;

    if (timeDifference <= 0) return '00000000';

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    // return `${padZero(days)}-${padZero(hours)}-${padZero(minutes)}-${padZero(seconds)}`;
    return `${padZero(days)}D-${padZero(hours)}H-${padZero(minutes)}M`;
}
export const padZero = (num: number): string => {
    return num.toString().padStart(2, '0');
};

export const getTimeAgo = (timestamp: number): string => {
    const now = Date.now();
    const timeDifference = now - (timestamp * 1000);

    const seconds = Math.floor(timeDifference / 1000);
    if (seconds < 60) {
        if (seconds < 0) {
            return `0sec`;
        } else {
            return `${seconds}secs`;
        }
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        return `${minutes}mins`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return `${hours}hrs`;
    }

    const days = Math.floor(hours / 24);
    return `${days}days`;
};

export function getRandomInt(length: number, exclude: number): number {
    // 创建一个包含所有可能整数的数组
    const range = Array.from({ length: length }, (_, i) => i);

    // 过滤掉需要排除的整数
    const filteredRange = range.filter(num => num !== exclude);

    // 从过滤后的数组中随机选择一个整数
    const randomIndex = Math.floor(Math.random() * filteredRange.length);

    return filteredRange[randomIndex];
}

const SECONDS_IN_ONE_DAY = 24 * 60 * 60; // 一天的秒数
const MAX_DAYS = 7; // 最大天数
const ONE_CHARGE_TIME = 300; // 一次充电的秒数
const CHARGES_PER_DAY = SECONDS_IN_ONE_DAY / ONE_CHARGE_TIME; // 一天内的充电次数
const MAX_CHARGES = CHARGES_PER_DAY * MAX_DAYS; // 最大可充次数 2016

export function getRemainingCharges(endTime: number): number {
    const currentTime = Math.floor(Date.now() / 1000); // 获取当前时间的秒级时间戳
    const remainingSeconds = endTime - currentTime; // 计算剩余秒数

    if (remainingSeconds <= 0) {
        return MAX_CHARGES;
    }

    const workEndSeconds = SECONDS_IN_ONE_DAY * MAX_DAYS - remainingSeconds
    const workEndCount = workEndSeconds / ONE_CHARGE_TIME

    return Math.floor(workEndCount)
}
export function calculatePercentage(value: number, total: number): number {
    if (total === 0) {
        throw new Error("error");
    }
    const percentage = (value / total) * 100;
    return percentage > 100 ? 100 : percentage;
}
export function formatTimestampToDate(timestamp: number): string {
    const date = new Date(timestamp);

    const year = date.getFullYear().toString().slice(-2); // 取后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需加1
    const day = String(date.getDate()).padStart(2, '0'); // 日期

    return `${year}-${month}-${day}`;
}

export function isMobileOrTablet() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|android|iphone|ipad|tablet|blackberry|windows phone|opera mini/.test(userAgent);
}

export function calculateRemainingTime(startTime: number): string {
    const currentTime = Date.now();
    const timeDifference = startTime - currentTime;

    if (timeDifference <= 0) return 'Available';

    const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
    const months = Math.floor((timeDifference % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
    const days = Math.floor((timeDifference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `${padZero(years)}Y-${padZero(months)}M-${padZero(days)}D`;
}

export function handlePermillage(value:any):string {
    if (value === null || value === undefined || isNaN(value)) return '';
    const [integerPart, decimalPart] = value.toString().split('.');
    // 使用千分号格式化整数部分
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // 如果有小数部分，合并整数部分和小数部分
    if (decimalPart) {
        return formattedIntegerPart + '.' + decimalPart;
    } else {
        return formattedIntegerPart;
    }
}
