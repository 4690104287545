import React, {useEffect, useRef, useState} from "react";
import { contractAbiMap, ContractAbiTypeEnum } from "src/enums/contractAbiEnum.ts";
import { networkList } from "src/hook/network.ts";
import { networkStatus } from "src/hook/networkStatus.ts";
import {useReadContract} from "wagmi";
const abi = JSON.parse(contractAbiMap.get(ContractAbiTypeEnum.AIR_DROP) as string)
const contractAddress: any = networkList[networkStatus].airDrop

const useGetStartTime = () => {
    const { data, isError, isLoading, isSuccess } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'startTime',
    });
    return { data, isError, isLoading, isSuccess };
};

const useClaimable = () => {
    const { data, isError, isLoading, isSuccess } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'claimable',
    });
    return { data, isError, isLoading, isSuccess };
};

const usePlayerAirDrop = (address: string) => {
    const { data, isError, isLoading, isSuccess } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'player',
        args: [address],
    });
    return { data, isError, isLoading, isSuccess };
};

const usePlayerDetail = (address: string) => {
    const { data, isError, isLoading, refetch, isSuccess } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'playerDetail',
        args: [address],
    });

    return { data, isError, isLoading, refetch, isSuccess };
};

const usePlayerStepsInfo = (address: string, steps: any) => {
    const { data, isSuccess, refetch, isFetched }: any = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'playerStepsInfo',
        args: [address, steps],
    });
    return { data, isSuccess, refetch, isFetched }
};

const useExchangeLogs = (address: string, steps: any) => {
    const { data, isError, isLoading, isSuccess } = useReadContract({
        abi: abi,
        address: contractAddress,
        functionName: 'exchangeLogs',
        args: [address, steps],
    });
    return { data, isError, isLoading, isSuccess };
};

export {
    useGetStartTime,
    useClaimable,
    usePlayerAirDrop,
    usePlayerDetail,
    usePlayerStepsInfo,
    useExchangeLogs,
}