import { useState, FC, ReactNode } from 'react';
import { GlobalStateContext } from './GlobalStateContext';

interface GlobalStateProviderProps {
    children: ReactNode;
}

export const GlobalStateProvider: FC<GlobalStateProviderProps> = ({ children }) => {
    const [isWhiteList, setIsWhiteList] = useState(true);
    const [address, setAddress] = useState('');
    const [musicStatus, setMusicStatus] = useState(false)
    const [netWorkStatus, setNetWorkStatus] = useState(0)
    const [btcAddress, setBtcAddress] = useState('')
    const [isSafePal, setIsSafePal] = useState(false)
    const [isStart, setIsStart] = useState(null as any)
    const [goldLoop, setGoldLoop] = useState(false)
    const [goldRecord, setGoldRecord] = useState(false)
    const [previousPath, setPreviousPath] = useState('')
    const [btcPrice, setBtcPrice] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    return (
        <GlobalStateContext.Provider
            value={{
                isWhiteList,
                setIsWhiteList,
                address,
                setAddress,
                musicStatus,
                setMusicStatus,
                netWorkStatus,
                setNetWorkStatus,
                setBtcAddress,
                btcAddress,
                isSafePal,
                setIsSafePal,
                isStart,
                setIsStart,
                goldLoop,
                setGoldLoop,
                goldRecord,
                setGoldRecord,
                previousPath,
                setPreviousPath,
                btcPrice,
                setBtcPrice,
                isLoading,
                setIsLoading,
            }}>
            {children}
        </GlobalStateContext.Provider>
    );
};