import React from "react";
import "./ConnectVisible.scss"
import JoyId from 'img/bitcell/joy-id.png'
import OkxIcon from 'img/bitcell/okx-icon.png'
import { useGlobalState } from "src/hook/useGlobalState.ts";
import { initOKXWallet } from "src/hook/OKXInit.ts";
import { initConfig, requestAccounts } from "@joyid/bitcoin";
import { networkStatus } from "src/hook/networkStatus.ts";
import { isMobileOrTablet } from "src/utils/utils.ts";
import { useDisconnect } from "wagmi";
interface ConnectVisibleProps {
    closeVisible: () => void
}
export const ConnectVisible: React.FC<ConnectVisibleProps> = ({ closeVisible }) => {
    const { setBtcAddress } = useGlobalState()
    const { disconnect } = useDisconnect()

    const connect = async (type: number) => {
        if (type === 3) {
            disconnect()
            await checkOkxWallet()
        } else {
            initJoyId(type)
        }
    }
    const checkOkxWallet = async () => {
        if (isMobileOrTablet()) {
            if (typeof (window as any).okxwallet !== 'undefined') {
                try {
                    const accounts = await (window as any).bitcoin.requestAccounts();
                    setBtcAddress(accounts[0])
                    closeVisible()
                    localStorage.setItem('btcWallet', 'okx')
                } catch (e) {
                    console.log('connect failed', e);
                }
            } else {
                initOKXWallet()
            }
        } else {
            const result = await connectOkx()
            if (result) {
                setBtcAddress(result[0])
                localStorage.setItem('btcWallet', 'okx')
                closeVisible()
            }
        }
    };
    const connectOkx = async () => {
        try {
            if (typeof (window as any).okxwallet !== 'undefined') {
                const res = await (window as any).okxwallet.bitcoin.requestAccounts();
                return res
            } else {
                console.log('OKX Wallet is not installed.');
            }
        } catch (error) {
            closeVisible()
        }
    }
    const initJoyId = async (type: number) => {
        initConfig({
            name: "Cellula",
            logo: "https://img.cellula.life/rankback/logo/logo.jpg",
            // joyidAppURL: networkStatus ? 'https://app.joy.id' : 'https://testnet.joyid.dev',
            joyidAppURL: 'https://app.joy.id',
            requestAddressType: type === 2 ? 'p2wpkh' : 'p2tr',
        });
        try {
            const accounts = await requestAccounts()
            setBtcAddress(accounts[0])
            localStorage.setItem('btcWallet', 'joyId')
            closeVisible()
        } catch (e) {
            closeVisible()
        }
    }
    return (
        <div className={'connect-visible'}>
            <div onClick={closeVisible} className={'close-icon'}></div>
            <div className={'title-bold'}>
                Connect to BTC Wallet
            </div>
            <div className={'connect-data'}>
                <div onClick={() => connect(1)} className={'connect-item'}>
                    <img className={'icon'} src={JoyId} />
                    <div className={'right'}>
                        <div className={'name'}>Joy Id</div>
                        <div className={'description'}>Tarproot</div>
                    </div>
                </div>
                <div onClick={() => connect(2)} className={'connect-item'}>
                    <img className={'icon'} src={JoyId} />
                    <div className={'right'}>
                        <div className={'name'}>Joy Id</div>
                        <div className={'description'}>Native SegWit</div>
                    </div>
                </div>
                <div onClick={() => connect(3)} className={'connect-item'}>
                    <img className={'icon'} src={OkxIcon} />
                    <div className={'right'}>
                        <div className={'name'}>OKX</div>
                    </div>
                </div>
            </div>
        </div>
    )
}