// useGlobalState.ts
import { useContext } from 'react';
import { GlobalStateContext } from './GlobalStateContext';

export const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (context === undefined) {
        throw new Error('useGlobalState must be used within a GlobalStateProvider');
    }
    return context;
};