import './records.scss'
import React, {useEffect, useRef, useState} from "react";
import {useStakingRewardRecord} from "src/hook/useStake.ts";
import { useGlobalState } from "src/hook/useGlobalState.ts";
import {formatDate, truncateNumber} from "src/utils/utils.ts";
import { formatEther, parseEther } from "viem";
import {NoData} from "src/view/components/noData.tsx";

interface recordsProps {
    title: string,
    closeVisible: () => void
}

export const Records: React.FC<recordsProps> = function ({title, closeVisible}) {
    const {address, isLoading, setIsLoading} = useGlobalState()
    const [tableTitle, setTableTitle] = useState([] as any)
    const [tableData, setTableData] = useState([] as any)
    // reward Record
    const startId = useRef(999999999)
    const pageSize = 6
    const { refetch: getReward } = useStakingRewardRecord(address, startId.current, pageSize)
    const [rewardRecord, setRewardRecord] = useState([] as dialogRecord[])
    const pageLoading = useRef(false)
    const getRewardRecord = () => {
        if (!pageLoading.current) {
            pageLoading.current = true
            getReward().then((res: any) => {
                setIsLoading(false)
                if (res.isSuccess && res.data.length > 0) {
                    const lastItemId = res.data[res.data.length - 1].id;
                    startId.current = Number(lastItemId) - 1
                    if (!rewardRecord.some(record => Number(record.id) === lastItemId)) {
                        const arr = res.data.map((record: dialogRecord) => {
                            return {
                                time: formatDate(Number(record.timestamp) * 1000),
                                amount: truncateNumber(formatEther(record.reward), 8),
                            }
                        })
                        setRewardRecord((prevItems:any) => [...prevItems, ...arr]);
                    }
                    pageLoading.current = false
                } else {
                    pageLoading.current = false
                }
            })
        }
    }
    const dataContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (dataContainerRef.current && rewardRecord.length) {
            dataContainerRef.current.addEventListener('scroll', handlePage);
        }
    }, [rewardRecord.length]);
    const handlePage = () => {
        if (!dataContainerRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = dataContainerRef.current;

        // 是否滚动到底部
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 300;
        if (isAtBottom) {
            getRewardRecord()
        }
    }
    // end
    const withdrawalRecordTitle = ['Amount', 'Date']

    const stakeRecordTitle = ['Staking Time', 'Staking Amount', 'Duration', 'Unlock Time']
    useEffect(() => {
        if (title === 'Withdraw Record') {
            setIsLoading(true)
            setTableTitle(withdrawalRecordTitle)
            getRewardRecord()
        } else {
            setTableTitle(stakeRecordTitle)
            // getRewardRecord()
        }
    }, []);
    // useEffect(() => {
    //     console.log(rewardRecord);
    //     const arr = rewardRecord.map((rewardRecord) => {
    //         return {
    //             stakingTime: formatDate(Number(rewardRecord.timestamp) * 1000),
    //             stakingAmount: truncateNumber(formatEther(rewardRecord.amount).toString(), 8),
    //             duration: `${(Number(rewardRecord.end_time) - Number(rewardRecord.timestamp)) / 3600 / 24}D`,
    //             unlockTime: formatDate(Number(rewardRecord.end_time) * 1000),
    //         }
    //     });
    //     setTableData(arr)
    // }, [rewardRecord]);
    useEffect(() => {

    }, [tableData]);
    return (
        <div className={'show-flex-box-r show-flex-center record-list-wrap'}>
            <div className={'bg'}>
                <div className={'left-arrow'} onClick={closeVisible}></div>
                <div onClick={closeVisible} className={'cursor-pointer close-icon'}></div>
                <div className={'color-white ff-Snasm title'}>{title}</div>
                <div className={'content'}>
                    {
                        title === 'Withdraw Record' && <div className={'withdraw-record'}>
                            <div className={'table-title'}>
                                {
                                    tableTitle.map((item: any, index: number) => (
                                        <div key={index}>{item}</div>
                                    ))
                                }
                            </div>
                            {
                                !isLoading && <div className={'table-content'} ref={dataContainerRef}>
                                    {
                                        rewardRecord.length > 0 ?
                                            <div>
                                                {
                                                    rewardRecord.map((item: any, index: number) => (
                                                        <div className={'single-row'} key={index}>
                                                            <div>
                                                                <div className={'phone-table-label'}>Amount
                                                                </div>
                                                                <div className={'value'}>{item.amount}</div>
                                                            </div>
                                                            <div>
                                                                <div className={'phone-table-label'}>Date</div>
                                                                <div className={'value'}>{item.time}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <div className={'w100 h100'}>
                                                <NoData></NoData>
                                            </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        title === 'Staking Record' && <div className={'stake-record'}>
                            <div className={'table-title'}>
                                {
                                    tableTitle.map((item: any, index: number) => (
                                        <div key={index}>{item}</div>
                                    ))
                                }
                            </div>
                            <div className={'table-content'} ref={dataContainerRef}>
                                {
                                    rewardRecord.map((item: any, index: number) => (
                                        <div className={'single-row'} key={index}>
                                            <div>
                                                <div className={'phone-table-label'}>Staking Time</div>
                                                <div className={'value'}>{item.stakingTime}</div>
                                            </div>
                                            <div>
                                                <div className={'phone-table-label'}>Staking Amount</div>
                                                <div className={'value'}>{item.stakingAmount}</div>
                                            </div>
                                            <div>
                                                <div className={'phone-table-label'}>Duration</div>
                                                <div className={'value'}>{item.duration}</div>
                                            </div>
                                            <div>
                                                <div className={'phone-table-label'}>Unlock Time</div>
                                                <div className={'value'}>{item.unlockTime}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}