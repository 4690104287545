import "./Welcome.scss"
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import Coin from 'img/welcome/coin.gif'
import Coin2 from 'img/welcome/coin-2.gif'
import Coin1 from 'img/welcome/coin-1.gif'
import anime from 'animejs';
import { getRandomInt } from "src/utils/utils.ts";
import { useGlobalState } from "src/hook/useGlobalState.ts";


export const WelcomePage = function () {
    const navigate = useNavigate()
    const avatarRef = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    const { setAddress } = useGlobalState()
    const addAnimationsFun = () => {
        if (avatarRef.current) {
            const domList = avatarRef.current.querySelectorAll('.star-img')
            if (domList.length) {
                domList.forEach((avatar: any) => {
                    startAnime(avatar)
                })
            }
        }
    }
    const startPositions = [['90%','-10%'],['120%','30%'],['70%','-20%']]
    const targetPositions = [['5%','110%'],['60%','110%'],['-50%','120%']]
    const startAnime = (target: HTMLDivElement) => {
        if (avatarRef.current) {
            const id = parseInt(target.id.replace(/[^0-9]/ig,""))
            target.style.opacity = '0'
            target.style.left = startPositions[id-1][0]
            target.style.top = startPositions[id-1][1]
            target.style.opacity = '1'
            anime({
                targets: target,
                left: targetPositions[id-1][0],
                top: targetPositions[id-1][1],
                duration: () => (5000),
                delay: id*1000,
                easing: 'easeInOutQuad',
                complete: () => {
                    setTimeout(() => {
                        startAnime(target)
                    }, getRandomInt(3,5)*1000)
                }
            });
        }
    }
    useEffect(() => {
        addAnimationsFun()
    }, []);
    useEffect(() => {
        if (token) {
            setAddress(null)
            localStorage.removeItem('token')
            navigate('/welcome')
        }
    }, [token]);
    const toCela = () => {
        const url = 'https://cellulalifegame.gitbook.io/cellula/economy'
        window.open(url, '_blank')
    }
    return (
        <div ref={avatarRef} className={'welcome-page'}>
            <div className={'welcome-container'}>
                <div className={'title title-1'}>Join to Share</div>
                <div className={'title-2'}>
                    <div className={'title'}>
                        50,000,000<br/>
                        CELA
                    </div>
                </div>
                <div className={'title title-3'}>
                    AIRDROP
                </div>
                <div className={'button-container'}>
                    <div className={'button-item'} onClick={toCela}>
                        What is CELA?
                    </div>
                    <div onClick={() => navigate('/airdrop')} className={'button-item'}>
                        CLAIM NOW
                    </div>
                </div>
            </div>
            <img src={Coin1} className={'coin-1'} alt=""/>
            <img src={Coin2} className={'coin-2'} alt=""/>
            <img src={Coin} className={'coin-3'} alt=""/>
            <img src={Coin2} className={'coin-4'} alt=""/>
            <div className={'star-img star-1'} id={'star-1'}></div>
            <div className={'star-img star-2'} id={'star-2'}></div>
            <div className={'star-img star-3'} id={'star-3'}></div>
        </div>
    )
}