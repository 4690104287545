import './withdraw.scss'
import React, { useEffect, useRef, useState } from "react";
import {
    useMinStakingAmount, useStakingDepositRecord,
    useStakingDurations, useStakingPendings,
    useStakingRewardPerBlock, useStakingRewardRecord,
    useStakingTotalShare, useStakingUserAmount,
    useStakingWithdrawRecord,
} from "src/hook/useStake.ts";
import {formatDate, truncateNumber, handlePermillage} from "src/utils/utils.ts";
import { BaseError, useAccount, useEstimateGas, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { useGlobalState } from "src/hook/useGlobalState.ts";
import { networkList } from "src/hook/network.ts";
import { networkStatus } from "src/hook/networkStatus.ts";
import { contractAbiMap, ContractAbiTypeEnum } from "src/enums/contractAbiEnum.ts";
import { useMessage } from "src/view/components/MessageContext.tsx";
import {formatEther} from "viem";
const cellTokenAbi = JSON.parse(contractAbiMap.get(ContractAbiTypeEnum.CELL_TOKEN) as string)
const stakingAbi = JSON.parse(contractAbiMap.get(ContractAbiTypeEnum.STAKING) as string)
const cellToken: any = networkList[networkStatus].tokenContract
const contractAddress: any = networkList[networkStatus].stakingContract
import {NoData} from "src/view/components/noData.tsx";
import {ethers} from "ethers";
interface withdrawProps {
    successRefresh: () => void
}
export const Withdraw: React.FC<withdrawProps> = function ({successRefresh}) {
    // withdraw
    const { address ,isLoading, setIsLoading} = useGlobalState()
    const { showMessage, closeMessage } = useMessage()
    const [positionIds, setPositionIds] = useState([] as number[])
    const { data: withdrawHash, isPending: withdrawIsPending, writeContractAsync: withdrawWriteContract, isSuccess: withdrawIsSuccess } = useWriteContract()
    const withdrawLoading = useRef(false)
    const { isSuccess: withdrawAsyncIsSuccess,isFetched: withdrawAsyncIsFetched, isError} = useWaitForTransactionReceipt({
        hash: withdrawHash
    })
    const { refetch: getStakingPendings } = useStakingPendings(positionIds)


    // reward Record
    const startId = useRef(999999999)
    const pageSize = 5
    const { refetch: getReward } = useStakingDepositRecord(address, startId.current, pageSize)
    const [rewardRecord, setRewardRecord] = useState([] as DepositRecord[])
    const pageLoading = useRef(false)
    const getRewardRecord = () => {
        if (!pageLoading.current) {
            pageLoading.current = true
            getReward().then((res: any) => {
                setIsLoading(false)
                if (res.isSuccess && res.data.length > 0) {
                    const lastItemId = res.data[res.data.length - 1].id;
                    startId.current = Number(lastItemId) - 1
                    if (!rewardRecord.some((record:any) => Number(record.id) === lastItemId)) {
                        const arr = res.data.filter((value:any) => value.share !== 0n).map((record: DepositRecord) => {
                            return {
                                amount: truncateNumber(formatEther(record.amount), 8),
                                duration: `${truncateNumber((Number(record.end_time) - Number(record.timestamp)) / 3600 / 24, 8)}D`,
                                type: Date.now() > Number(record.end_time) * 1000 ? 1 : 2,
                                time: formatDate(Number(record.end_time) * 1000),
                                position_index: record.position_index
                            }
                        })
                        setRewardRecord((prevItems:any) => [...prevItems, ...arr]);
                    }
                    pageLoading.current = false
                } else {
                    pageLoading.current = false
                }
            })
        }
    }
    const dataContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (dataContainerRef.current && rewardRecord.length) {
            dataContainerRef.current.addEventListener('scroll', handlePage);
        }
    }, [rewardRecord.length]);
    const handlePage = () => {
        if (!dataContainerRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = dataContainerRef.current;

        // 是否滚动到底部
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 300;
        if (isAtBottom) {
            getRewardRecord()
        }
    }
    // end

    // reward Record Bottom
    const startIdBottom = useRef(999999999)
    const pageSizeBottom = 5
    const { refetch: getRewardBottom } = useStakingWithdrawRecord(address, startIdBottom.current, pageSizeBottom)
    const [rewardRecordBottom, setRewardRecordBottom] = useState([] as withdrawRecord[])
    const pageLoadingBottom = useRef(false)
    const getRewardRecordBottom = () => {
        if (!pageLoadingBottom.current) {
            pageLoadingBottom.current = true
            getRewardBottom().then((res: any) => {
                setIsLoading(false)
                if (res.isSuccess && res.data.length > 0) {
                    const lastItemId = res.data[res.data.length - 1].id;
                    startIdBottom.current = Number(lastItemId) - 1
                    if (!rewardRecordBottom.some(record => Number(record.id) === lastItemId)) {
                        const arr = res.data.map((record: withdrawRecord) => {
                            return {
                                realAmount: truncateNumber(formatEther(record.amount), 8),
                                time: formatDate(Number(record.timestamp) * 1000)
                            }
                        })
                        setRewardRecordBottom((prevItems:any) => [...prevItems, ...arr]);
                    }
                    pageLoadingBottom.current = false
                } else {
                    pageLoadingBottom.current = false
                }
            })
        }
    }
    const dataContainerRefBottom = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (dataContainerRefBottom.current && rewardRecordBottom.length) {
            dataContainerRefBottom.current.addEventListener('scroll', handlePageBottom);
        }
    }, [rewardRecordBottom.length]);
    const handlePageBottom = () => {
        if (!dataContainerRefBottom.current) return;
        const { scrollTop, scrollHeight, clientHeight } = dataContainerRefBottom.current;

        // 是否滚动到底部
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 300;
        if (isAtBottom) {
            getRewardRecordBottom()
        }
    }
    // end
    useEffect(() => {
        if (withdrawIsPending) {
            setTimeout(() => {
                showMessage('Withdraw...',0)
            }, 200)
        } else if (!withdrawIsSuccess) {
            withdrawLoading.current = false
            setCurrentPositionIndex(null)
            setGasParams(null)
            setGasLimit(0n)
            closeMessage()
        }
    }, [withdrawIsPending]);
    const withdrawSuccess = () => {
        setTimeout(() => {
            refresh()
            setCurrentPositionIndex(null)
            setGasParams(null)
            setGasLimit(0n)
            showMessage('Successfully Withdraw')
        }, 200)
    }
    const refresh = () => {
        successRefresh()
        startId.current = 999999999
        startIdBottom.current = 999999999
        setRewardRecord([])
        setRewardRecordBottom([])
        setTimeout(() => {
            getRewardRecord()
            getRewardRecordBottom()
        }, 0);
    }
    useEffect(() => {
        if (withdrawAsyncIsSuccess) {
            withdrawSuccess()
        }
    }, [withdrawAsyncIsSuccess]);
    useEffect(() => {
        if (isError) {
            refresh()
        }
    }, [isError]);
    useEffect(() => {
        closeMessage()
        withdrawLoading.current = false
        setCurrentPositionIndex(null)
        setGasParams(null)
        setGasLimit(0n)
    }, [withdrawAsyncIsFetched]);
    useEffect(() => {
        getRewardRecord()
        getRewardRecordBottom()
        setIsLoading(true)
    }, []);
    const [currentPositionIndex, setCurrentPositionIndex] = useState(null as any)
    const withdrawContract = () => {
        if (withdrawLoading) {
            withdrawWriteContract({
                address: contractAddress,
                abi: stakingAbi,
                functionName: 'withdraw',
                args: [currentPositionIndex],
                gasPrice: networkStatus ? 1000001234n : 5000001234n,
                gas: gasLimit
            })
                .catch((error) => {
                    if (error) {
                        const shortMessage = (error as BaseError)?.shortMessage;
                        if (shortMessage !== 'User rejected the request.') {
                            closeMessage()
                            if (shortMessage?.includes(':')) {
                                setTimeout(() => {
                                    showMessage(shortMessage?.split(':')[1].trim())
                                }, 100)
                            } else {
                                setTimeout(() => {
                                    showMessage(shortMessage)
                                }, 100)
                            }
                        }
                        refresh()
                    }
                    withdrawLoading.current = false
                    setCurrentPositionIndex(null)
                    setGasParams(null)
                    setGasLimit(0n)
                })
        }
    }
    const account = useAccount()
    const withdrawCurrent = (positionIndex: number) => {
        const targetId = networkList[networkStatus].id
        if (targetId !== account?.chainId) {
            showMessage('Wrong Network')
            return
        }
        if (!withdrawLoading.current) {
            withdrawLoading.current = true
            setCurrentPositionIndex(positionIndex)
        }
    }
    // gas computed
    useEffect(() => {
        if (currentPositionIndex !== null) {
            withdrawGasComputed()
        }
    }, [currentPositionIndex]);
    const withdrawGasComputed = () => {
        const iface = new ethers.Interface(stakingAbi)
        const data: any = iface.encodeFunctionData('withdraw', [currentPositionIndex])
        setGasParams({
            data: data
        })
    }
    const [gasParams, setGasParams] = useState<any | null>(null)
    const estimateGas = useEstimateGas({
        to: contractAddress,
        data: gasParams?.data
    })
    const [gasLimit, setGasLimit] = useState<bigint>(0n)
    useEffect(() => {
        if (gasLimit !== 0n) {
            withdrawContract()
        }
    }, [gasLimit]);
    useEffect(() => {
        if (gasParams !== null) {
            estimateGas.refetch().then((res: any) => {
                if (res.isSuccess) {
                    const newGas = parseInt((parseInt(res.data.toString()) * 1.3).toString())
                    setGasLimit(BigInt(newGas))
                }
            })
        }
    }, [gasParams]);
    const tableTitle = ['Amount', 'Staking Duration', '']
    const recordTitle = ['Date', 'Amount']
    return (
        <div className={'withdraw-wrap'}>
            {
                !isLoading && <div>
                    <div className={'table-wrap'}>
                        <div className={'title'}>
                            {
                                tableTitle.map((title, index) => (
                                    <div className={'single-title'} key={index}>{title}</div>
                                ))
                            }
                        </div>
                        <div className={'content'} ref={dataContainerRef}>
                            {
                                rewardRecord.length > 0 ?
                                    <div>
                                        {
                                            rewardRecord.map((item: any, index: number) => (
                                                <div className={'single-row'} key={index}>
                                                    <div>
                                                        <div className={'phone-table-label'}>Amount</div>
                                                        <div>{handlePermillage(item.amount)} CELA</div>
                                                    </div>
                                                    <div>
                                                        <div className={'phone-table-label'}>Staking Duration</div>
                                                        <div>{item.duration}</div>
                                                    </div>
                                                    <div>
                                                        <div className={'phone-table-label'}></div>
                                                        {
                                                            item.type === 1 && <div className={'withdraw-btn-wrap'}>
                                                                <div
                                                                    onClick={() => withdrawCurrent(Number(item.position_index))}
                                                                    className={'show-flex-box-r show-flex-center cursor-pointer withdraw-btn'}>Withdraw
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            item.type === 2 &&
                                                            <div className={'time'}>{item.time} Unlock</div>
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className={'w100 h100'}>
                                        <div className={'w100 h100 pl-26'}>
                                            <NoData></NoData>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className={'title-wrap'}>
                        <div className={'title'}>Withdraw Record</div>
                    </div>
                    <div className={'record-wrap'}>
                        <div className={'title'}>
                            {
                                recordTitle.map((item, index) => (
                                    <div key={index} className={'single-title'}>{item}</div>
                                ))
                            }
                        </div>
                        <div className={'content'} ref={dataContainerRefBottom}>
                            {
                                rewardRecordBottom.length > 0 ?
                                    <div>
                                        {
                                            rewardRecordBottom.map((item, index) => (
                                                <div className={'single-row'} key={index}>
                                                    <div>
                                                        <div className={'phone-table-label'}>Date</div>
                                                        <div>{item.time}</div>
                                                    </div>
                                                    <div>
                                                        <div className={'phone-table-label'}>Amount</div>
                                                        <div>{handlePermillage(item.realAmount)} CELA</div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className={'w100 h100'}>
                                        <NoData></NoData>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}